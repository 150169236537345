import { useState } from 'react';
import { debounce, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Colors } from 'constants/colors';
import { StyledOutlinedInput } from '../../styles';
import { FontSizes } from '../../../../constants/font-sizes';
import { ReactComponent as DefaultSearch } from 'assets/icons/default-search.svg';
import { ReactComponent as ActiveSeatch } from 'assets/icons/active-search.svg';
import { useDispatch } from 'react-redux';
import { setPageNumber } from 'store/modules/pagination/pagination.reducer';
import { setTablePageNumber } from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';

interface Props {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  dataTestId: string;
  tableId?: string;
  width?: string;
}

export const SearchInput = ({
  searchQuery,
  setSearchQuery,
  dataTestId,
  tableId = '',
  width,
}: Props) => {
  const dispatch = useDispatch();
  const [searchQueryState, setSearchQueryState] = useState(searchQuery);
  const selectedColumnFilter = useSelectedColumnSelector(tableId);
  const handleSearchQueryChange = (event: any) => {
    setSearchQueryState(event.target.value);
    dispatch(setPageNumber(1));
    if (selectedColumnFilter)
      dispatch(
        setTablePageNumber({
          table: tableId,
          page: 1,
        }),
      );

    const updateSearchQuery = debounce(() => {
      setSearchQuery(event.target.value);
    }, 500);
    updateSearchQuery();
  };

  const removeQueryFilter = () => {
    setSearchQuery('');
    setSearchQueryState('');
  };

  return (
    <StyledOutlinedInput
      size="small"
      data-testid={dataTestId}
      style={{
        fontSize: FontSizes.fontSize14,
        height: '36px',
        width: width || '200px',
        borderRadius: '5px',
        padding: '10px',
        border: '1px solid #6F8198',
        boxShadow: `0px 5px 10px 0px ${Colors.lightGray}`,
      }}
      placeholder="Search"
      value={searchQueryState}
      onChange={handleSearchQueryChange}
      startAdornment={
        <InputAdornment position="start">
          {!searchQueryState ? <DefaultSearch /> : <ActiveSeatch />}
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment sx={{ width: '11px' }} position="end">
          {searchQuery && (
            <Close
              onClick={removeQueryFilter}
              sx={{
                width: '11px',
                height: '11px',
                color: Colors.darkGray,
                cursor: 'pointer',
              }}
            />
          )}
        </InputAdornment>
      }
      inputProps={{
        style: {
          fontFamily: '"Poppins", sans-serif',
          fontSize: FontSizes.fontSize14,
          fontWeight: 400,
          lineHeight: '14px',
          textAlign: 'left',
          color: Colors.darkGray,
        },
      }}
    />
  );
};
