import { TableColumnProps } from 'components/table/table-component/table-component';

export const identitiesWatchHideTableStructure: TableColumnProps[] = [
  {
    id: 'login',
    title: 'Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'identity_status',
    title: 'Identity Status',
    withSort: true,
    position: 'left',
    sortColumn: 'external',
  },
  {
    id: 'visibility_options',
    title: '',
    withSort: false,
    position: 'right',
  },
];

export const assetWatchHideTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'visibility_options',
    title: '',
    withSort: false,
    position: 'right',
  },
];

export const teamsWatchHideTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'visibility_options',
    title: '',
    withSort: false,
    position: 'right',
  },
];

export enum ResourcesEnum {
  Identities = 'identities',
  Assets = 'assets',
  Teams = 'teams',
}

export const resourceTableStructureMap = {
  [ResourcesEnum.Identities]: identitiesWatchHideTableStructure,
  [ResourcesEnum.Assets]: assetWatchHideTableStructure,
  [ResourcesEnum.Teams]: teamsWatchHideTableStructure,
};
