/* eslint-disable @typescript-eslint/no-unused-vars */
import { CustomSvgIcon } from '../svg-icon/svg-icon';
import { FontSizes } from '../../constants/font-sizes';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { ReactComponent as ListIcon } from 'assets/icons/list-icon.svg';
import { Colors } from 'constants/colors';
import { styled } from '@mui/material';

export interface StyledIconButtonProps extends IconButtonProps {
  collapsed: boolean;
}

// @ts-ignore
export const StyledCollapseIcon = ({ expanded, ...props }) => {
  return (
    <IconButton
      sx={{
        position: 'fixed',
        left: expanded ? '300px' : '65px',
        background: Colors.white,
        height: '30px',
        width: '30px',
        color: Colors.hovered,
        padding: '10px',
        marginTop: '14px',
        marginLeft: '-15px',
        marginRight: '0px',
        border: `2px solid ${Colors.collapseBorder}`,
        transform: expanded ? '' : 'rotate(180deg)',
        zIndex: 1001,
        transition: 'left 0.3s ease',

        '&:hover': {
          background: Colors.white,
          border: `2px solid ${Colors.white}`,
        },
      }}
      {...props}
    ></IconButton>
  );
};
