import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaginationStore {
  page: number;
  itemsPerPage: number;
  totalItems: number;
}

const initialState: PaginationStore = {
  page: 1,
  itemsPerPage: 10,
  totalItems: 0,
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPageNumber: (state: PaginationStore, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setItemsPerPage: (
      state: PaginationStore,
      action: PayloadAction<number>,
    ) => {
      state.itemsPerPage = action.payload;
    },
    setTotalItems: (state: PaginationStore, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
  },
});

export const { setItemsPerPage, setPageNumber, setTotalItems } =
  paginationSlice.actions;

export const paginationReducer = paginationSlice.reducer;
