import { styled } from '@mui/material';
import { TextField } from '@mui/material';
import { Colors } from 'constants/colors';

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    height: 36,
  },
  '&.MuiInputBase-sizeSmall': {
    height: '36px',
  },
  '& .MuiInputLabel-root': {
    color: `${Colors.darkGray}!important`,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: `${Colors.blackPearl}!important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${Colors.sectionBorder} !important`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${Colors.blackPearl} !important`,
  },
  '& .MuiOutlinedInput-input': {
    textFillColor: `${Colors.blackPearl}!important`,
  },
  '& .MuiFormLabel-root': {
    top: '-2px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: Colors.blackPearl,
    },
  },
}));
