import {
  ERROR_KEY,
  SNACKBAR_201_MSG,
  SNACKBAR_204_MSG,
  SNACKBAR_400_MSG,
  SNACKBAR_401_MSG,
  SNACKBAR_403_MSG,
  SNACKBAR_404_MSG,
  SNACKBAR_409_MSG,
  SNACKBAR_500_MSG,
  SNACKBAR_SUCCESS_MSG,
  SUCCESS_KEY,
} from 'components/error-handler/constants';
import {
  AssetsRepositoriesObj,
  AssetsRepositoriesObjsRes,
  IdentitiesUserObj,
  IdentitiesUserObjRes,
} from './types';
import { getRepoOwnersString } from 'utils/assets/assets-utils';
import { getRepositoryStatus } from 'utils/map/map-utils';

export const transformIdentitiesUsersResponse = (
  response: IdentitiesUserObjRes,
): IdentitiesUserObj => {
  return {
    ...response,
    data: response?.data?.map((item) => ({
      ...item,
      identity_status: item.external ? 'External' : 'Internal',
    })),
  };
};

export const transformRepositoriesResponse = (
  response: AssetsRepositoriesObjsRes,
): AssetsRepositoriesObj => {
  return {
    ...response,
    data: response?.data?.map((item) => ({
      ...item,
      owners: getRepoOwnersString(item.owners_list),
      status: getRepositoryStatus(item),
    })),
  };
};

export const transDependencyGraphDataResponse = (response: any) => {
  const { edges, nodes } = response;
  return {
    nodes,
    edges,
  };
};

export const transformErrorResponse = (error: any) => {
  const responseStatus = error?.status;
  const message = error?.error;

  switch (responseStatus) {
    case 200:
      return {
        ...error,
        type: SUCCESS_KEY,
        message: message || SNACKBAR_SUCCESS_MSG,
      };
    case 201:
      return {
        ...error,
        type: SUCCESS_KEY,
        message: message || SNACKBAR_201_MSG,
      };
    case 204:
      return {
        ...error,
        type: SUCCESS_KEY,
        message: message || SNACKBAR_204_MSG,
      };
    case 400:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_400_MSG,
      };
    case 401:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_401_MSG,
      };
    case 403:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_403_MSG,
      };
    case 404:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_404_MSG,
      };
    case 409:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_409_MSG,
      };
    case 500:
      return {
        ...error,
        type: ERROR_KEY,
        message: message || SNACKBAR_500_MSG,
      };
    default:
      return {
        ...error,
        type: ERROR_KEY,
        message: message,
      };
  }
};
