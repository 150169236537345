import React from 'react';
import { Typography } from '@mui/material';
import { Colors } from 'constants/colors';

export const GlobalSearchResponse = (props: any) => {
  const { output, children, searched } = props;
  const renderContent = () => {
    if (!searched) {
      return '';
    } else {
      if (output === null || output === undefined) {
        return (
          <Typography
            variant={'body1'}
            sx={{ opacity: 0.5, color: Colors.blackPearl }}
          >
            No Data for the particular query
          </Typography>
        );
      } else if (Object.keys(output)?.length !== 4) {
        return (
          <Typography
            variant={'body1'}
            sx={{ opacity: 0.5, color: Colors.blackPearl }}
          >
            {output}
          </Typography>
        );
      } else {
        return <>{children}</>;
      }
    }
  };

  return <>{renderContent()}</>;
};
