import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { PlainModals } from 'store/modules/modals/modals.reducer';
import { ConnectorRemoveModal } from 'pages/connectors/components/connector-details/components/connector-remove-modal/connector-remove-modal';
import { SendReportModal } from 'pages/reporting/components/modals/send-report-modal/send-report-modal';
import { InviteUserModal } from 'pages/user-management/components/modals/invite-user-modal/invite-user-modal';
import { RemoveUserModal } from 'pages/user-management/components/modals/remove-user-modal/remove-user-modal';
import { GroupTeamModal } from 'pages/identities/identity-details/components/modals/group-team-modal/group-team-modal';
import { AssetsRunsModal } from 'pages/assets/asset-details/components/modals/assets-runs-modal/assets-runs-modal';
import { PackageAdvisoryModal } from 'pages/assets/asset-details/components/modals/package-advisory-modal/package-advisory-modal';
import { PackageRepositoriesModal } from 'pages/assets/asset-details/components/modals/package-repositories-modal/package-repositories-modal';
import { IdentitiesEmailsModal } from 'pages/identities/components/modals/identities-emails-modal/identities-emails-modal';
import { ContributorsReputationModal } from 'pages/opensource-dependencies/opensource-dependency-details/components/modals/contributor-reputation/contributor-reputation';
import { MembersModal } from 'pages/teams/components/modals/members-modal/members-modal';
import { MissingModal } from 'pages/teams/components/modals/missing-modal/missing-modal';
import { RepositoryImportersModal } from 'pages/opensource-dependencies/opensource-dependency-details/components/modals/repository-importers.tsx/repository-importers';
import { RiskReasonModal } from '../../pages/identities/identity-details/components/modals/risk-reason-modal/risk-reason-modal';
import { DetailsJsonModal } from '../../pages/identities/identity-details/components/audit-trail/components/modal/details-json-modal';
import { ConfirmationNotesModal } from '../../pages/compliance-overview/components/modal/confirmation-notes-modal';
import { NotesViewerModal } from '../../pages/compliance-overview/components/modal/notes-viewer-modal';
import { ViewInfoModal } from 'pages/compliance-overview/components/modal/view-info-modal';
import { AdvisoriesModal } from 'pages/opensource-dependencies/opensource-dependency-details/components/modals/advisories-modal/advisories-modal';
import { TrivyScanModal } from 'pages/trivy-scan/components/trivy-scan-modal';
import { PRSecretsModal } from 'pages/pr-secrets/components/pr-secrets-modal';
import { AssetVulnerabilityCounts } from 'pages/assets/components/asset-vulnerability-counts/asset-vulnerability-counts';

export const Modals = () => {
  // const isPdfOpenModal = useIsPlainModalOpenSelector(
  //   PlainModals.PDF_PREVIEW_MODAL,
  // );
  const isConnectorRemoveOpenModal = useIsPlainModalOpenSelector(
    PlainModals.CONNECTORS_REMOVE_INTEGRATION_MODAL,
  );
  const isSendReportOpenModal = useIsPlainModalOpenSelector(
    PlainModals.SEND_REPORT_MODAL,
  );
  const isInviteUserOpenModal = useIsPlainModalOpenSelector(
    PlainModals.INVITE_USER_MODAL,
  );
  const isRemoveUserOpenModal = useIsPlainModalOpenSelector(
    PlainModals.REMOVE_USER_MODAL,
  );
  const isGroupTeamOpenModal = useIsPlainModalOpenSelector(
    PlainModals.GROUP_TEAM_MODAL,
  );
  const isAssetsRunsOpenModal = useIsPlainModalOpenSelector(
    PlainModals.ASSETS_RUNS_MODAL,
  );
  const isPackageAdvisoryOpenModal = useIsPlainModalOpenSelector(
    PlainModals.PACKAGE_ADVISORY_MODAL,
  );
  const isPackageRepositoriesOpenModal = useIsPlainModalOpenSelector(
    PlainModals.PACKAGE_REPOSITORIES_MODAL,
  );
  const isIdentitiesEmailsOpenModal = useIsPlainModalOpenSelector(
    PlainModals.IDENTITIES_EMAILS_MODAL,
  );
  const isMembersOpenModal = useIsPlainModalOpenSelector(
    PlainModals.TEAMS_MEMBERS_MODAL,
  );

  const isMissingUsersOpenModal = useIsPlainModalOpenSelector(
    PlainModals.MISSING_USERS_MODAL,
  );

  const isRepositoryImportersModalOpen = useIsPlainModalOpenSelector(
    PlainModals.REPOSITORY_IMPORTERS_MODAL,
  );

  const isAdvisoriesModalOpen = useIsPlainModalOpenSelector(
    PlainModals.ADVISORIES_MODAL,
  );

  const isContributorsReputationModalOpen = useIsPlainModalOpenSelector(
    PlainModals.CONTRIBUTORS_REPUTATION_MODAL,
  );

  const isIdentityDetailsRiskReasonsEvidenceModalOpen =
    useIsPlainModalOpenSelector(
      PlainModals.IDENTITIES_DETAILS_RISK_REASON_MODAL,
    );

  const isIdentityDetailsDetailsAuditJsonModalOpen =
    useIsPlainModalOpenSelector(
      PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL,
    );

  const isConfirmationNotesModalOpen = useIsPlainModalOpenSelector(
    PlainModals.CONFIRMATION_NOTES_MODAL,
  );

  const isNotesViewerModalOpen = useIsPlainModalOpenSelector(
    PlainModals.NOTES_VIEWER_MODAL,
  );

  const isViewInfoModalOpen = useIsPlainModalOpenSelector(
    PlainModals.VIEW_INFO_MODAL,
  );

  const isTrivyScanModalOpen = useIsPlainModalOpenSelector(
    PlainModals.TRIVY_SCAN_MODAL,
  );

  const isPRSecretsModalOpen = useIsPlainModalOpenSelector(
    PlainModals.PR_SECRETS_MODAL,
  );

  const isAssetsVulnerabilityCountsOpen = useIsPlainModalOpenSelector(
    PlainModals.ASSET_VULNERABILITY_COUNTS_MODAL,
  );

  return (
    <>
      {/* {isPdfOpenModal && <PdfPreviewModal />} */}
      {isConnectorRemoveOpenModal && <ConnectorRemoveModal />}
      {isSendReportOpenModal && <SendReportModal />}
      {isInviteUserOpenModal && <InviteUserModal />}
      {isRemoveUserOpenModal && <RemoveUserModal />}
      {isGroupTeamOpenModal && <GroupTeamModal />}
      {isAssetsRunsOpenModal && <AssetsRunsModal />}
      {isPackageAdvisoryOpenModal && <PackageAdvisoryModal />}
      {isPackageRepositoriesOpenModal && <PackageRepositoriesModal />}
      {isIdentitiesEmailsOpenModal && <IdentitiesEmailsModal />}
      {isMembersOpenModal && <MembersModal />}
      {isMissingUsersOpenModal && <MissingModal />}
      {isRepositoryImportersModalOpen && <RepositoryImportersModal />}
      {isAdvisoriesModalOpen && <AdvisoriesModal />}
      {isContributorsReputationModalOpen && <ContributorsReputationModal />}
      {isIdentityDetailsRiskReasonsEvidenceModalOpen && <RiskReasonModal />}
      {isIdentityDetailsDetailsAuditJsonModalOpen && <DetailsJsonModal />}
      {isConfirmationNotesModalOpen && <ConfirmationNotesModal />}
      {isNotesViewerModalOpen && <NotesViewerModal />}
      {isViewInfoModalOpen && <ViewInfoModal />}
      {isTrivyScanModalOpen && <TrivyScanModal />}
      {isPRSecretsModalOpen && <PRSecretsModal />}
      {isAssetsVulnerabilityCountsOpen && <AssetVulnerabilityCounts />}
    </>
  );
};
