import { FilterItem } from 'components/multi-filter/multi-filter';

export const MembersFilters = (): FilterItem[] => {
  return [
    {
      title: 'Risk Rating',
      filterId: 'highest_severity',
      filterColumns: ['highest_severity'],
      props: [
        {
          label: 'Critical',
          value: '5',
        },
        {
          label: 'High',
          value: '4',
        },
        {
          label: 'Medium',
          value: '3',
        },
        {
          label: 'Low',
          value: '2',
        },
        {
          label: 'None',
          value: '0',
        },
      ],
    },
  ];
};
