import { ReactNode, SyntheticEvent } from 'react';
import {
  Tabs,
  Tab,
  TabProps,
  Input,
  TableCell,
  TableCellProps,
  Box,
  styled,
  Typography,
  OutlinedInput,
  Pagination,
} from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { fontSecondary } from 'constants/font-family';

interface StyledTabsProps {
  children?: ReactNode;
  value: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} />
))({
  minHeight: '25px',

  '& .MuiTabs-scroller': {
    height: '25px',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiButtonBase-root': {
    padding: '1px',
    minHeight: '25px',
    width: 'auto',
  },
});

export const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
  () => ({
    textTransform: 'none',
    fontSize: FontSizes.fontSize11,
    color: Colors.white,
    border: `1px solid ${Colors.darkCyan}`,
    borderRightWidth: '0',
    minWidth: '70px',

    '&:first-of-type': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },

    '&:last-of-type': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderRightWidth: '1px',
    },

    '&.MuiButtonBase-root': {
      fontSize: FontSizes.fontSize11,
      width: 'auto',
    },
    '&.Mui-selected': {
      color: Colors.blackPearl,
      backgroundColor: Colors.white,
    },
    '&.Mui-focusVisible': {
      backgroundColor: Colors.blackPearl,
    },
  }),
);

export const StyledInput = styled(Input)(() => ({
  fontSize: FontSizes.fontSize10,
  lineHeight: '24px',
  backgroundColor: 'transparent',
  color: Colors.blackPearl,

  '&.MuiInput-root:after': {
    borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    transform: 'none',
    transition: 'none',
  },

  '&.MuiInput-root.Mui-error:after': {
    borderColor: Colors.red,
  },

  '&.MuiInput-underline': {
    '&:hover:before': {
      borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    },
  },

  '&.Mui-focused': {
    borderColor: Colors.darkDesaturatedCyan,
  },
}));

interface TableHeaderCellProps extends TableCellProps {
  withSort?: boolean;
  clickColumnFilter?: (e: any) => void;
  alignSortArrow?: boolean;
  AssignedFilterCellIcon?: JSX.Element;
}

export const StyledTableHeaderCell = styled((props: TableHeaderCellProps) => (
  <TableCell
    {...props}
    sx={{
      color: `${Colors.blackPearl} !important`,
      textTransform: 'none !important',
      fontWeight: '600 !important',
      fontFamily: fontSecondary,
      fontSize: `${FontSizes.fontSize14} !important`,
      padding: '14px 20px',
      borderBottom: '0px',
    }}
  >
    {props.children}
    {props.AssignedFilterCellIcon && (
      <CustomSvgIcon
        onClick={props.clickColumnFilter}
        sx={{
          fontSize: FontSizes.fontSize14,
          width: '14px',
          height: '14px',
          position: 'relative',
          top: '1px',
          left: '4px',
          cursor: 'pointer',
        }}
      >
        {props.AssignedFilterCellIcon && props.AssignedFilterCellIcon}
      </CustomSvgIcon>
    )}
  </TableCell>
))({
  fontSize: FontSizes.fontSize14,
  fontWeight: '500',
  color: Colors.blackPearl,
  backgroundColor: Colors.tableHeader,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const StyledTableBodyCell = styled(TableCell)(() => ({
  fontSize: FontSizes.fontSize14,
  color: Colors.blackPearl,
  fontFamily: fontSecondary,
  backgroundColor: 'transparent',
  position: 'relative',
  height: '50px',
  padding: '15px 20px',
}));

export const TableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const EmptyTableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '25px',
}));

export const RightTableFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '10px',
  marginLeft: 'auto',
}));

export const CellLabel = styled(Typography)(() => ({
  backgroundColor: Colors.blue,
  fontSize: FontSizes.fontSize10,
  color: Colors.white,
  padding: '5px 7px',
  borderRadius: '100px',
  width: '80px',
  textAlign: 'center',
  lineHeight: '12px',
}));

export const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  minWidth: '200px',
  fontSize: FontSizes.fontSize10,
  lineHeight: '24px',
  backgroundColor: 'transparent',
  color: Colors.darkGray,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.sectionBorder,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.sectionBorder,
  },
  '&:hover > .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.sectionBorder,
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  height: '32px',

  '& .MuiPaginationItem-root': {
    color: Colors.darkGray,
    gap: '10px',
    border: `1px solid ${Colors.tableBorder}`,
    padding: '5px',
    borderRadius: '5px',
    width: '32px',
    margin: '0px 5px',
    lineHeight: '22px',
    fontWeight: 500,
    fontFamily: fontSecondary,
    backgroundColor: 'inherit',
  },

  '& .MuiPaginationItem-root.Mui-selected': {
    background: Colors.tabGradient,
    color: Colors.white,
    border: 'none',
    boxShadow: `10px 0px 25px 0px ${Colors.bodyBackground}`,
  },

  '& MuiPagination-ul li': {
    color: Colors.darkGray,
    border: `1px solid ${Colors.tableBorder}`,
  },
}));
