import { ModalContainer } from 'components/modals/modal-container/modal-container';
import {
  TableColumnProps,
  TableComponent,
} from 'components/table/table-component/table-component';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';
import { paths } from 'constants/routes';
import { ASSETS_VULNERABILITY_COUNTS_TABLE } from 'constants/test-ids';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  setActiveFindingsTab,
  setActiveTab,
} from 'store/modules/assets/assets.reducer';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { setApplicableFilters } from 'store/modules/selected-column/selected-column.reducer';

export const AssetVulnerabilityCounts = () => {
  const [searchParams] = useSearchParams();
  const sourceParam: any = searchParams.get('source');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAssetsVulnerabilityCountsOpen = useIsPlainModalOpenSelector(
    PlainModals.ASSET_VULNERABILITY_COUNTS_MODAL,
  );

  const { vulnerabilityCounts, repo, owner, source } =
    useGetModalMetaData() as unknown as {
      vulnerabilityCounts: any;
      repo: string;
      owner: string;
      source: string;
    };

  const handleCloseModal = () => {
    dispatch(
      closeModalByNameAction(PlainModals.ASSET_VULNERABILITY_COUNTS_MODAL),
    );
  };

  const columns = ['oss', 'secret', 'sast', 'iac'];
  const columnNames = ['SCA', 'Secrets', 'SAST', 'IaC'];

  const tableData = columns.map((key, index) => {
    return {
      name: `${columnNames[index]}`,
      type: key,
      count: vulnerabilityCounts[key] || 0,
    };
  });

  const tableColumns: TableColumnProps[] = [
    {
      id: 'name',
      title: 'Vulnerability',
      position: 'left',
    },
    {
      id: 'count',
      title: 'Count',
      position: 'left',
    },
  ];

  const renderCountCell = (item: any) => {
    const handleClick = () => {
      if (item?.type.includes('oss')) {
        dispatch(setActiveTab(3));
        setTimeout(() => {
          const tableId = TableNamesOfFilters.ASSET_EXTERNAL_PACKAGES;
          dispatch(
            setApplicableFilters({
              table: tableId,
              applicableFilters: { severity: ['CRITICAL', 'HIGH'] },
            }),
          );
        }, 0);
      } else if (item?.type === 'secret') {
        dispatch(setActiveTab(6));
        dispatch(setActiveFindingsTab(0));
      } else if (item?.type === 'sast') {
        dispatch(setActiveTab(6));
        dispatch(setActiveFindingsTab(1));
      } else if (item?.type === 'iac') {
        dispatch(setActiveTab(6));
        dispatch(setActiveFindingsTab(2));
      }

      navigate(
        `${paths.assetsDetails}?id=${owner}:${repo}&source=${
          source || sourceParam
        }${item?.type.includes('oss') ? '&candh=true' : ''}`,
      );
      dispatch(
        closeModalByNameAction(PlainModals.ASSET_VULNERABILITY_COUNTS_MODAL),
      );
    };
    return (
      <TableCellWithLink
        itemTitle={item?.count}
        handleClick={handleClick}
        dataTestId="vulnerability_counts_cell"
        isActive
      />
    );
  };

  return (
    <ModalContainer
      isOpenModal={isAssetsVulnerabilityCountsOpen}
      handleCloseModal={handleCloseModal}
      title={'Vulnerability Counts'}
      modalWidth="fit-content"
    >
      <TableComponent
        tableId={ASSETS_VULNERABILITY_COUNTS_TABLE}
        isEmptyList={!tableData.length}
        isListErrored={false}
        isListFetching={false}
        isListLoading={false}
        list={tableData}
        tableColumns={tableColumns}
        dataTestId={ASSETS_VULNERABILITY_COUNTS_TABLE}
        extraCellsRenderObj={{ count: renderCountCell }}
        notPaginated
      />
    </ModalContainer>
  );
};
