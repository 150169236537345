import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { StyledButton } from '../../button/button';
import { fontSecondary } from 'constants/font-family';

interface Props {
  title?: string;
  goBackHandler?: () => void;
}

export const HeaderBackButton = ({ title, goBackHandler }: Props) => {
  const navigate = useNavigate();

  const goBack = () => (goBackHandler ? goBackHandler() : navigate(-1));

  return (
    <Box>
      <Typography>
        <StyledButton
          onClick={goBack}
          variant="text"
          size="large"
          color="info"
          sx={{ fontFamily: fontSecondary }}
          startIcon={<ArrowBack sx={{ width: '9px', height: '9px' }} />}
        >
          {title || 'Back'}
        </StyledButton>
      </Typography>
    </Box>
  );
};
