import { Box, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

export const ErrorPage = () => {
  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        fontFamily={fontSecondary}
        fontSize={FontSizes.fontSize16}
        color={Colors.whiteTransparent05}
      >
        Something went wrong! Try again!
      </Typography>
    </Box>
  );
};
