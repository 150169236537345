export const SUCCESS_KEY = 'success';
export const WARNING_KEY = 'warning';
export const ERROR_KEY = 'error';

export const SNACKBAR_400_MSG = 'Bad Request – Error 400';
export const SNACKBAR_401_MSG = 'HTTP 401 Error – Unauthorized';
export const SNACKBAR_403_MSG = 'You are not authorized to view this page';
export const SNACKBAR_404_MSG = 'We can’t find the page you’re looking for';
export const SNACKBAR_409_MSG = 'Request could not be processed';
export const SNACKBAR_500_MSG = 'Internal Server Error';
export const SNACKBAR_DEFAULT_MSG =
  'Sorry Something went wrong please try again!';
export const SNACKBAR_SUCCESS_MSG = 'Successfully Processed Request';
export const SNACKBAR_201_MSG = 'Successfully created';
export const SNACKBAR_204_MSG = 'Successfully deleted';
