import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum Roles {
  admin = 'admin',
  user = 'user',
  developer = 'developer',
}

export const checkViewerPermissions = (roles: String[]) =>
  roles.some((role) => role === 'user' || role === 'admin');

export interface AuthState {
  roles: String[];
  userInfo: Record<any, any>;
}

const initialState: AuthState = {
  roles: [],
  userInfo: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addRoles: (state, action: PayloadAction<String[]>) => {
      state.roles = action.payload;
    },
    addUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
  },
});

export const { addRoles, addUserInfo } = authSlice.actions;

export const authReducer = authSlice.reducer;
