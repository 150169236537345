import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'report_name',
    title: 'Report Name',
    position: 'left',
  },
  {
    id: 'triggered_by',
    title: 'Triggered By',
    position: 'left',
  },
  {
    id: 'triggered_at',
    title: 'Triggered On',
    position: 'left',
    isDate: true,
    dateFormat: 'MM/DD/YYYY HH:mm',
  },
  {
    id: 'status',
    title: 'Status',
    position: 'left',
  },
  {
    id: 'action_items',
    title: '',
    position: 'center',
  },
];

export enum FileType {
  PDF = 'pdf',
  CSV = 'csv',
}
