import { Box, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

interface Props {
  error?: any;
}

export const ErrorHandler = ({ error }: Props) => {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Typography
        fontFamily={fontSecondary}
        fontSize={FontSizes.fontSize14}
        color={Colors.darkGray}
      >
        {error ? error.message : 'Error'}
      </Typography>
    </Box>
  );
};
