import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Colors } from 'constants/colors';
import { useEffect, useState } from 'react';

export const NavTooltip = (props: any) => {
  const { children, title, shouldOpen, placement, isTooltipOpen } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isTooltipOpen) {
      setOpen(false);
    }
  }, [isTooltipOpen]);

  const handleOpen = () => {
    if (!shouldOpen) {
      return;
    }
    setOpen(true);
  };
  return (
    <NavStyledTooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={title}
      placement={placement}
    >
      {children}
    </NavStyledTooltip>
  );
};

const NavStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement={props.placement ? props.placement : 'top'}
    classes={{ popper: className }}
    disableFocusListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.white,
    '&::before': {
      border: `1px solid ${Colors.white}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: '10px 0px 15px 0px #E1E7F0',
    p: '5px 10px',
    border: `1px solid ${Colors.white}`,
    color: Colors.hovered,
    fontFamily: '"Poppins", sans-serif',
  },
}));
