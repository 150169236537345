import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FilterItem, MultiFilter } from 'components/multi-filter/multi-filter';
import { BranchesFilterList } from './constants';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import {
  Filter,
  setActiveSortedField,
  setActiveSortedOrder,
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
  setTablePageNumber,
} from 'store/modules/selected-column/selected-column.reducer';
import { tableStructure } from 'pages/branches/constants';
import { SortOrder } from 'utils/sort-order/sort';
import { setPageNumber } from 'store/modules/pagination/pagination.reducer';

interface Props {
  tableId: string;
}

export const BranchesMultiFilter = ({ tableId }: Props) => {
  const dispatch = useDispatch();
  const selectedColumnFilter = useSelectedColumnSelector(tableId);
  const { activeSortedField, activeSortedOrder } =
    useSelectedColumnSelector(tableId);
  const { tableFilterChips } = useSelectedColumnSelector(tableId);
  const {
    sorting_field,
    sorting_order,
    filterTitles: teamTitles,
    ...filterChips
  } = tableFilterChips;

  const [filtersObj, setFiltersObj] = useState<Filter>(filterChips);
  const [displayFilters, setDisplayFilters] =
    useState<FilterItem[]>(BranchesFilterList);
  const [currentOrder, setCurrentOrder] = useState('');

  useEffect(() => {
    setDisplayFilters(() =>
      BranchesFilterList.filter((item) => {
        if (!item?.filterColumns) return false;
        return (
          item?.filterColumns?.findIndex(
            (col) => col === selectedColumnFilter?.previousColumn,
          ) !== -1
        );
      }),
    );
    if (activeSortedField === selectedColumnFilter?.previousColumn) {
      setCurrentOrder(activeSortedOrder === '+' ? 'asc' : 'des');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterHandler = (
    filterName: string,
    filterValue?: string | boolean | string[],
  ) =>
    setFiltersObj({
      ...filtersObj,
      [filterName]:
        filtersObj[filterName] === filterValue ? undefined : filterValue,
    });

  const showResultsHandler = () => {
    const totalFilters = { ...tableFilterChips, ...filtersObj };
    const filters = {
      account_status: totalFilters.account_status
        ? totalFilters.account_status === 'active'
        : undefined,
      team_member: totalFilters.hasOwnProperty('team_member')
        ? totalFilters.team_member
        : undefined,
      internal_account:
        totalFilters.identity_status &&
        totalFilters.identity_status === 'internal'
          ? true
          : undefined,
      external_account:
        totalFilters.identity_status &&
        totalFilters.identity_status === 'external'
          ? true
          : undefined,
      unknown:
        totalFilters.identity_status &&
        totalFilters.identity_status === 'unknown'
          ? true
          : undefined,
      account_type: totalFilters.account_type
        ? totalFilters.account_type
        : undefined,
      only_watched: totalFilters.hasOwnProperty('only_watched')
        ? totalFilters.only_watched
        : undefined,
      only_hidden: totalFilters.hasOwnProperty('only_hidden')
        ? totalFilters.only_hidden
        : undefined,
    };
    const filterTitles = Object.keys(filtersObj).map((colId) => {
      const title =
        BranchesFilterList.find((item) => item?.filterId === colId)?.title ||
        '';
      return { id: colId, title, column: selectedColumnFilter?.previousColumn };
    });
    const previousFilterTitles =
      typeof tableFilterChips?.filterTitles === 'object'
        ? tableFilterChips?.filterTitles
        : [];
    dispatch(
      setFilterChips({
        table: tableId,
        tableFilterChips: {
          ...tableFilterChips,
          ...filtersObj,
          filterTitles: [...filterTitles, ...previousFilterTitles],
        },
      }),
    );
    // if (isEqual(filters, applicableFilters)) if (currentOrder === '') return;

    dispatch(
      setApplicableFilters({
        table: tableId,
        applicableFilters: { ...filters },
      }),
    );
    if (currentOrder !== '') {
      applySort(currentOrder ? selectedColumnFilter?.previousColumn : '');
      dispatch(
        setFilterChips({
          table: tableId,
          tableFilterChips: {
            ...tableFilterChips,
            sorting_order: currentOrder !== '' ? currentOrder : undefined,
            sorting_field: selectedColumnFilter?.previousColumn,
          },
        }),
      );
    }

    dispatch(
      setHighlightColumns({
        table: tableId,
        highlightColumns: [
          ...selectedColumnFilter?.highlightColumns,
          {
            id: selectedColumnFilter?.previousColumn,
            title:
              tableStructure.find(
                (item) => item?.id === selectedColumnFilter?.previousColumn,
              )?.title || '',
          },
        ],
      }),
    );
    dispatch(setPageNumber(1));
    if (selectedColumnFilter)
      dispatch(
        setTablePageNumber({
          table: tableId,
          page: 1,
        }),
      );
  };

  const applySort = (field: string) => {
    dispatch(
      setActiveSortedField({
        table: tableId,
        activeSortedField: field,
      }),
    );
    dispatch(
      setActiveSortedOrder({
        table: tableId,
        activeSortedOrder:
          currentOrder === 'asc' ? SortOrder.Asc : SortOrder.Desc,
      }),
    );
  };

  const removeFiltersHandler = () => {
    setFiltersObj({});
    dispatch(setFilterChips({ table: tableId, tableFilterChips: {} }));
    dispatch(setApplicableFilters({ table: tableId, applicableFilters: {} }));
    applySort('');
    setCurrentOrder('');
    dispatch(setHighlightColumns({ table: tableId, highlightColumns: [] }));
  };

  return (
    <MultiFilter
      isLast={false}
      showResultsHandler={showResultsHandler}
      setFilterHandler={setFilterHandler}
      filtersObj={filtersObj}
      removeFiltersHandler={removeFiltersHandler}
      filters={displayFilters}
      currentOrder={currentOrder}
      setCurrentOrder={setCurrentOrder}
    />
  );
};
