import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const advisoriesInfoStructure: InfoDetailsProps[] = [
  {
    id: 'pkg',
    title: 'Package',
  },
  {
    id: 'pkg_version',
    title: 'Version',
  },
];

export const advisoriesTableStructure: TableColumnProps[] = [
  {
    id: 'CVE',
    title: 'CVE',
    position: 'left',
  },
  {
    id: 'published',
    title: 'Published Date',
    position: 'left',
  },
  {
    id: 'summary',
    title: 'Summary',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
];
