import dayjs from 'dayjs';

interface OwnerItem {
  display_name?: string;
  login: string;
  user_id: string;
}

export const getRepoOwnersString = (owners: OwnerItem[] | undefined) => {
  if (!owners) return '';

  return Object.values(owners)
    .map((item) => (item.display_name ? item.display_name : item.login))
    .join(', ');
};

export const getContributorsPermissionsString = (
  permissions: Record<string, any>[] | undefined,
) => {
  if (!permissions || !permissions.length) return '';

  return permissions
    .map((item) =>
      Object.keys(item)
        .map((key) => item[key])
        .join(', '),
    )
    .join(', ');
};

export const getLastActionDate = (
  actionsList: Record<string, string>[] | undefined,
) => {
  if (!actionsList || !actionsList.length) return '-';

  return Object.keys(actionsList[0]).map((key) =>
    actionsList[0]?.[key] ? dayjs(actionsList[0][key]).format('L LT') : '-',
  );
};
