import { CustomSvgIcon } from '../svg-icon/svg-icon';
import { FontSizes } from '../../constants/font-sizes';
import { ReactComponent as ListIcon } from 'assets/icons/list-icon.svg';
import { StyledCollapseIcon } from './styles';
import { NAVIGATION_DRAWER_EXPAND_BUTTON } from 'constants/test-ids';
import { Colors } from 'constants/colors';

export const CollapseIcon = (props: any) => {
  const { isDrawerExpanded, setIsDrawerExpanded, setExpandClick, expandClick } =
    props;
  return (
    <StyledCollapseIcon
      expanded={isDrawerExpanded}
      color="inherit"
      aria-label="open drawer"
      onClick={() => {
        setIsDrawerExpanded(!isDrawerExpanded);
        setExpandClick(!expandClick);
      }}
      data-testid={NAVIGATION_DRAWER_EXPAND_BUTTON}
    >
      <CustomSvgIcon sx={{ fontSize: FontSizes.fontSize12 }}>
        <ListIcon color={Colors.hovered} />
      </CustomSvgIcon>
    </StyledCollapseIcon>
  );
};
