import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'repo_name',
    title: 'Repository name',
    position: 'left',
  },
  {
    id: 'branch_name',
    title: 'Branch Name',
    position: 'left',
  },
  {
    id: 'protected',
    title: 'Protection',
    position: 'left',
  },
  {
    id: 'environment',
    title: 'Environment',
    position: 'left',
  },
];
