import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const accessTableStructureDev: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    position: 'left',
    sortColumn: 'repo_slug',
  },
  {
    id: 'org',
    title: 'Organization',
    position: 'left',
    sortColumn: 'repo_slug',
  },
  {
    id: 'highest_permission',
    title: 'Permissions',
    position: 'left',
  },
  {
    id: 'options',
    title: 'Options',
    position: 'left',
  },
];

export const accessTableStructureAdmin: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    position: 'left',
    withSort: true,
    sortColumn: 'repo_slug',
    withSearch: true,
  },
  {
    id: 'org',
    title: 'Organization',
    withSort: true,
    noFilters: true,
    position: 'left',
    sortColumn: 'repo_slug',
  },
  {
    id: 'highest_permission',
    title: 'Permissions',
    withSort: true,
    position: 'left',
  },
  {
    id: 'permission_used',
    title: 'Permissions Used',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Accessed',
    withSort: true,
    noFilters: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'count',
    title: 'Group/Team',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'options',
    title: 'Options',
    position: 'left',
  },
];

export const IdentitiesAccessFilterList: FilterItem[] = [
  {
    title: 'OverPrivileged',
    filterId: 'only_overprivilege',
    filterColumns: ['name'],
    props: [
      {
        label: 'OverPrivileged',
        value: true,
      },
      {
        label: 'Not OverPrivileged',
        value: false,
      },
    ],
  },
  {
    title: 'Critical',
    filterId: 'is_critical',
    filterColumns: ['name'],
    props: [
      {
        label: 'Is critical',
        value: true,
      },
      {
        label: 'Is not critical',
        value: false,
      },
    ],
  },
  {
    title: 'Highest Permission',
    filterId: 'permissions',
    filterColumns: ['highest_permission'],
    props: [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Maintain',
        value: 'maintain',
      },
      {
        label: 'Write',
        value: 'write',
      },
      {
        label: 'Triage',
        value: 'triage',
      },
      {
        label: 'Read',
        value: 'read',
      },
    ],
  },
  {
    title: 'Permission used',
    filterId: 'permission_used',
    filterColumns: ['permission_used'],
    props: [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Maintain',
        value: 'maintain',
      },
      {
        label: 'Write',
        value: 'write',
      },
      {
        label: 'Triage',
        value: 'triage',
      },
      {
        label: 'Read',
        value: 'read',
      },
      {
        label: 'No permission used',
        value: 'no permission',
      },
    ],
  },
  {
    title: 'Is accessed',
    filterId: 'is_accessed',
    filterColumns: ['name'],
    props: [
      {
        label: 'Is accessed',
        value: true,
      },
      {
        label: 'Is never accessed',
        value: false,
      },
    ],
  },
];
