import { ModalContainer } from '../../../../components/modals/modal-container/modal-container';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from '../../../../store/modules/modals/modals.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from '../../../../store/modules/modals/modals.reducer';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Colors } from 'constants/colors';

export const ConfirmationNotesModal = () => {
  const dispatch = useDispatch();
  const modalMetaData = useGetModalMetaData() as unknown as {
    notes: string;
    handleSubmit: (notes: string) => void;
  };
  const [currentNotes, setCurrentNotes] = useState(modalMetaData.notes);

  const isConfirmationNotesOpenModal = useIsPlainModalOpenSelector(
    PlainModals.CONFIRMATION_NOTES_MODAL,
  );
  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.CONFIRMATION_NOTES_MODAL));
  };

  const handleModalConfirmation = () => {
    modalMetaData.handleSubmit(currentNotes);
  };

  return (
    <ModalContainer
      isOpenModal={isConfirmationNotesOpenModal}
      handleCloseModal={handleCloseModal}
      confirmModalTitle={'Submit'}
      handleConfirm={handleModalConfirmation}
      title={'Notes'}
    >
      <textarea
        value={currentNotes}
        onChange={(event) => setCurrentNotes(event.target.value)}
        style={{
          backgroundColor: Colors.sectionBackground,
          color: Colors.blackPearl,
          height: '95px',
          width: '350px',
          marginTop: '20px',
          marginLeft: '-20px',
          padding: '5px',
        }}
      />
    </ModalContainer>
  );
};
