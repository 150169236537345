import { configureStore } from '@reduxjs/toolkit';
import { defaultBaseApi } from 'store/apis/base';
import { authReducer } from 'store/modules/auth/auth.reducer';
import { connectorsReducer } from 'store/modules/connectors/connectors.reducer';
import { modalsReducer } from 'store/modules/modals/modals.reducer';
import { filtersReducer } from 'store/modules/filters/filters.reducer';
import { identitiesReducer } from 'store/modules/identities/identities.reducer';
import { assetsReducer } from 'store/modules/assets/assets.reducer';
import { branchesReducer } from 'store/modules/branches/branches.reducer';
import { teamsReducer } from 'store/modules/teams/teams.reducer';
import { locationsReducer } from './modules/location/location.reducer';
import { selectedColumnReducer } from './modules/selected-column/selected-column.reducer';
import { tenantSettingsWatchHideReducer } from './modules/tenant-settings-watch-hide/tenant-settings-watch-hide.reducer';
import { alertsReducer } from './modules/alerts/alerts.reducer';
import { identitiesDetailsReducer } from './modules/identities/identities-details/identities-details.reducer';
import { globalSearchReducer } from './modules/global-search/global-search.reducer';
import { paginationReducer } from './modules/pagination/pagination.reducer';

export const initiateStore = (baseApi: typeof defaultBaseApi) => {
  const reducer = {
    auth: authReducer,
    connectors: connectorsReducer,
    modals: modalsReducer,
    filters: filtersReducer,
    locations: locationsReducer,
    identities: identitiesReducer,
    identitiesDetails: identitiesDetailsReducer,
    assets: assetsReducer,
    branches: branchesReducer,
    teams: teamsReducer,
    tenantSettingsWatchHide: tenantSettingsWatchHideReducer,
    alerts: alertsReducer,
    globalSearch: globalSearchReducer,
    selectedColumn: selectedColumnReducer,
    pagination: paginationReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  };
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      if (baseApi) {
        return getDefaultMiddleware().concat([baseApi?.middleware]);
      }
      return getDefaultMiddleware();
    },

    devTools: process.env.NODE_ENV !== 'production',
  });
};

const defaultStore = initiateStore(defaultBaseApi);
export type RootState = ReturnType<typeof defaultStore.getState>;

export type AppDispatch = typeof defaultStore.dispatch;
