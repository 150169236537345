import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';
import { ResourcesEnum } from '../../../pages/tenant-settings-watch-hide/constants';

export interface TenantSettingsWatchHideTableStates {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
}

export interface TenantSettingsWatchHideState {
  [ResourcesEnum.Identities]: TenantSettingsWatchHideTableStates;
  [ResourcesEnum.Teams]: TenantSettingsWatchHideTableStates;
  [ResourcesEnum.Assets]: TenantSettingsWatchHideTableStates;
}

const initialState: TenantSettingsWatchHideState = {
  [ResourcesEnum.Identities]: {
    activeSortedField: 'last_activity',
    activeSortedOrder: SortOrder.Desc,
  },
  [ResourcesEnum.Teams]: {
    activeSortedField: 'name',
    activeSortedOrder: SortOrder.Asc,
  },
  [ResourcesEnum.Assets]: {
    activeSortedField: 'name',
    activeSortedOrder: SortOrder.Asc,
  },
};

export const tenantSettingsWatchHideSlice = createSlice({
  name: 'tenantSettingsWatchHide',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: TenantSettingsWatchHideState,
      action: PayloadAction<{ resource: ResourcesEnum; field: string }>,
    ) => {
      state[action.payload.resource].activeSortedField = action.payload.field;
    },
    setActiveSortedOrder: (
      state: TenantSettingsWatchHideState,
      action: PayloadAction<{
        resource: ResourcesEnum;
        sortedOrder: SortOrder;
      }>,
    ) => {
      state[action.payload.resource].activeSortedOrder =
        action.payload.sortedOrder;
    },
  },
});

export const { setActiveSortedField, setActiveSortedOrder } =
  tenantSettingsWatchHideSlice.actions;

export const tenantSettingsWatchHideReducer =
  tenantSettingsWatchHideSlice.reducer;
