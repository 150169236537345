import { useAppSelector } from '../../hooks';
import { SortOrder } from 'utils/sort-order/sort';

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ assets }) => assets.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ assets }) => assets.activeSortedOrder);

export const useActivePipelineActivityWorkflowIdSelector = (): number | null =>
  useAppSelector(({ assets }) => assets.activePipelineActivityWorkflowId);

export const useActiveDependencyPackageSelector = (): string | undefined =>
  useAppSelector(({ assets }) => assets.activeDependencyPackage);

export const useActiveTabSelector = (): number =>
  useAppSelector(({ assets }) => assets.activeTab);

export const useActiveFindingsTabSelector = (): number =>
  useAppSelector(({ assets }) => assets.activeFindingsTab);

export const useCustomTabSelector = (): number =>
  useAppSelector(({ assets }) => assets.customTab);

export const useMergedPullRequestsSelector = (): boolean =>
  useAppSelector(({ assets }) => assets.mergedPullRequests);

export const useOSSLanguagesSelector = (): string[] =>
  useAppSelector(({ assets }) => assets.ossLanguages);
