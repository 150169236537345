import { useDispatch } from 'react-redux';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from '../../../../../../store/modules/modals/modals.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from '../../../../../../store/modules/modals/modals.reducer';
import { ModalContainer } from '../../../../../../components/modals/modal-container/modal-container';
import React, { useContext } from 'react';
import { IdentityRiskReasons } from '../../../../../../store/apis/types';
import { TableComponent } from '../../../../../../components/table/table-component/table-component';
import { tableStructure } from './constant';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../../../components/providers/api-provider/api-provider';
import { TableCellWithLink } from '../../../../../../components/table/table-components/table-components';
import { SeverityChip } from '../../../../../policies/components/severity-chip/severity-chip';
import {
  IDENTITY_DETAILS_RISK_REASON_TABLE,
  IDENTITY_RISK_REASON_POLICY_LINK,
} from 'constants/test-ids';

interface RiskReasonTableData {
  name: string;
  severity: number;
  count: number;
}

export const RiskReasonModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metaData = useGetModalMetaData();
  const apiContext = useContext(ApiContext);
  const { data, isLoading, isError } =
    metaData.type === 'identity'
      ? apiContext.baseApi?.useGetIdentityRiskReasonsQuery(
          metaData.id ? metaData.id : '',
        )
      : apiContext.baseApi?.useGetRepositoryRiskReasonsQuery(
          metaData.id ? metaData.id : '',
        );

  // const mockData: IdentityRiskReasons = {
  //   data: {
  //     findings: {
  //       '3': [
  //         {
  //           name: 'Commits by external email domain',
  //           count: 1004,
  //         },
  //       ],
  //       '5': [
  //         {
  //           name: 'Unapproved Pull Request merged to pipeline',
  //           count: 80,
  //         },
  //         {
  //           name: 'Deactivated IAM users active on SDLC tools',
  //           count: 1,
  //         },
  //       ],
  //     },
  //   },
  // };

  const generateRiskReasonTableData = (riskReasonRes: IdentityRiskReasons) => {
    const riskReasonTableData: RiskReasonTableData[] = [];
    for (const severity in riskReasonRes.data.findings) {
      for (const riskDetail of riskReasonRes.data.findings[severity]) {
        riskReasonTableData.push({
          severity: parseInt(severity),
          ...riskDetail,
        });
      }
    }
    riskReasonTableData.sort((a, b) => b.severity - a.severity);
    return riskReasonTableData;
  };

  const isRiskReasonEvidenceOpenModal = useIsPlainModalOpenSelector(
    PlainModals.IDENTITIES_DETAILS_RISK_REASON_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(
      closeModalByNameAction(PlainModals.IDENTITIES_DETAILS_RISK_REASON_MODAL),
    );
  };

  const navigatePolicy = (policyName: string) => {
    localStorage.setItem('policyLogin', metaData?.id || '');
    handleCloseModal();
    navigate(`/policy?policy=${policyName}`);
  };
  const renderPolicyName = (data: RiskReasonTableData) => (
    <TableCellWithLink
      handleClick={() => navigatePolicy(data.name)}
      isActive={true}
      itemTitle={data.name}
      dataTestId={IDENTITY_RISK_REASON_POLICY_LINK}
    />
  );

  const renderSeverityChip = (data: RiskReasonTableData) => (
    <SeverityChip severityScore={data.severity} />
  );

  const extraCellRendererObj = {
    name: renderPolicyName,
    severity: renderSeverityChip,
  };

  return (
    <ModalContainer
      isOpenModal={isRiskReasonEvidenceOpenModal}
      title="Risk Reasons"
      handleCloseModal={handleCloseModal}
      modalWidth="850px"
      modalHeight="500px"
    >
      <TableComponent
        tableId={IDENTITY_DETAILS_RISK_REASON_TABLE}
        isListLoading={isLoading}
        isListErrored={isError}
        isEmptyList={false}
        tableHeight="400px"
        isListFetching={false}
        dataTestId={IDENTITY_DETAILS_RISK_REASON_TABLE}
        list={generateRiskReasonTableData(data || { data: { findings: [] } })}
        tableColumns={tableStructure}
        extraCellsRenderObj={extraCellRendererObj}
        notPaginated
      />
    </ModalContainer>
  );
};
