import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_URL,
  realm: process.env.REACT_APP_REALM || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
});

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens);
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

interface Props {
  children: ReactNode;
}
const RootApp: FC<Props> = ({ children }) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        pkceMethod: 'S256',
        checkLoginIframe: false,
        onLoad: 'login-required',
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      {children}
    </ReactKeycloakProvider>
  );
};
root.render(
  <BrowserRouter>
    <RootApp>
      <App />
    </RootApp>
  </BrowserRouter>,
);

reportWebVitals();
