import { TableColumnProps } from '../../../../../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Policy Name',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'count',
    title: 'Violations Count',
    position: 'left',
  },
];
