import { FilterItem } from 'components/multi-filter/multi-filter';

export const TeamsFilterList: FilterItem[] = [
  {
    title: 'Risk Rating',
    filterId: 'highest_severity',
    filterColumns: ['team_risk_score'],
    props: [
      {
        label: 'Critical',
        value: '5',
      },
      {
        label: 'High',
        value: '4',
      },
      {
        label: 'Medium',
        value: '3',
      },
      {
        label: 'Low',
        value: '2',
      },
      {
        label: 'None',
        value: '0',
      },
    ],
  },
  {
    title: 'Active',
    filterId: 'is_team_status_active',
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Watched',
    filterId: 'only_watched',
    props: [
      {
        label: 'Watched',
        value: true,
      },
      {
        label: 'Not Watched',
        value: false,
      },
    ],
  },
  {
    title: 'Hidden',
    filterId: 'only_hidden',
    props: [
      {
        label: 'Hidden',
        value: true,
      },
      {
        label: 'Not Hidden',
        value: false,
      },
    ],
  },
];
