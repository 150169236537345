export enum SortOrder {
  Asc = '+',
  Desc = '-',
}

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export const sortAlphanumericArr = <T, K extends keyof T>(
  arr: T[],
  key: K,
): T[] =>
  arr.sort((prev, current) =>
    collator.compare(
      String(prev[key]).toLowerCase(),
      String(current[key]).toLowerCase(),
    ),
  );
