import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'head_sha',
    title: 'Head Sha',
    position: 'left',
  },
  {
    id: 'org',
    title: 'Organization',
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    position: 'left',
  },
  {
    id: 'pr_number',
    title: 'PR Number',
    position: 'center',
  },
  {
    id: 'check_run_id',
    title: 'Check Run Id',
    position: 'left',
  },
  {
    id: 'completed',
    title: 'Completed Time',
    position: 'left',
  },
];

export const trivyScanInfoStructure: InfoDetailsProps[] = [
  {
    id: 'check_run_id',
    title: 'Check Run ID',
  },
  {
    id: 'type',
    title: 'Scan Type',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'repo',
    title: 'Repository',
  },
  {
    id: 'pr_number',
    title: 'PR Number',
  },
];

export const trivyScanDetailsTableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'file',
    title: 'File Name',
    position: 'left',
  },
  {
    id: 'action',
    title: 'Action',
    position: 'left',
  },
];
