import { Box, Typography } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { useDispatch } from 'react-redux';
import {
  PlainModals,
  closeModalByNameAction,
} from 'store/modules/modals/modals.reducer';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';

export const PRSecretsModal = () => {
  const dispatch = useDispatch();
  const metaData = useGetModalMetaData();
  const { scan } = metaData;

  const isPRSecretsScanModalOpen = useIsPlainModalOpenSelector(
    PlainModals.PR_SECRETS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.PR_SECRETS_MODAL));
  };

  let code: { [key: string]: string } = {};
  scan?.CauseMetadata?.Code?.Lines?.forEach(
    (line: { Number: any; Content: string }) => {
      code[String(line.Number)] = line.Content;
    },
  );

  return (
    <ModalContainer
      isOpenModal={isPRSecretsScanModalOpen}
      title="PR Secrets Scan Details"
      handleCloseModal={handleCloseModal}
      modalHeight="500px"
      modalWidth="700px"
      alignItems="start"
    >
      <Box mt={2}>
        {scan?.CauseMetadata?.Provider && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Provider:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.CauseMetadata?.Provider}
            </Typography>
          </Box>
        )}
        {scan?.CauseMetadata?.Service && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Service:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.CauseMetadata?.Service}
            </Typography>
          </Box>
        )}
        {scan?.message && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Message:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.message}
            </Typography>
          </Box>
        )}
        {scan?.resolution && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Resolution:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.resolution}
            </Typography>
          </Box>
        )}
        {scan?.CauseMetadata?.StartLine && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Start Line:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.CauseMetadata?.StartLine}
            </Typography>
          </Box>
        )}
        {scan?.CauseMetadata?.EndLine && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              End Line:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
            >
              {scan?.CauseMetadata?.EndLine}
            </Typography>
          </Box>
        )}
        {scan.CauseMetadata?.Code?.Lines && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px', mb: 1 }}
            >
              Content:{' '}
            </Typography>
            <JsonViewer
              value={code}
              theme={'dark'}
              sx={{
                'background-color': Colors.sectionBackground,
                color: Colors.blackPearl,
              }}
              rootName={false}
              displayDataTypes={false}
              quotesOnKeys={false}
              displaySize={false}
              collapseStringsAfterLength={100}
            />
          </Box>
        )}
      </Box>
    </ModalContainer>
  );
};
