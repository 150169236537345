import { useAppSelector } from '../../hooks';

export const useGetRoles = () =>
  useAppSelector(({ auth }) => {
    return auth.roles;
  });

export const useGetUserInfo = () =>
  useAppSelector(({ auth }) => {
    return auth.userInfo;
  });
