import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'report_name',
    title: 'Report Name',
    position: 'left',
  },
  {
    id: 'report_description',
    title: 'Report Description',
    position: 'left',
  },
];

export const teamVulnerabilityScoreTableStructure: TableColumnProps[] = [
  {
    id: 'team_name',
    title: 'Team Name',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'team_vulnerability_score',
    title: 'Vulnerability Score',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'repository_count',
    title: '# Repository',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'total_vuln',
    title: 'Total Vuln.',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'average_vuln_per_repo',
    title: 'Avg. Vuln. Per Repo',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'oss_count',
    title: '# SCA',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'secret_count',
    title: '# Secrets',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'sast_count',
    title: '# SAST',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'iac_count',
    title: '# IaC',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  { id: 'Team Lead', title: 'Team Lead', position: 'left' },
  { id: 'Manager 1', title: 'Manager 1', position: 'left' },
  { id: 'Manager 2', title: 'Manager 2', position: 'left' },
];
