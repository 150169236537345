import { Colors } from 'constants/colors';

export const transformInactiveIdentitiesResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData: !response.service && !response.human,
    chartData: [
      {
        labels: [
          `Human (${Math.round(
            (Number(response.human) * 100) /
              (Number(response.service) + Number(response.human)),
          )}%)`,
          `Service (${Math.round(
            (Number(response.service) * 100) /
              (Number(response.service) + Number(response.human)),
          )}%)`,
        ],
        values: [response.human, response.service],
        marker: {
          colors: [Colors.lightPurple, Colors.kikorangiBlue],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};

export const transformExternalUsersResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData: !response.active && !response.inactive,
    chartData: [
      {
        labels: [
          `Active (${Math.round(
            (Number(response.active) * 100) /
              (Number(response.inactive) + Number(response.active)),
          )}%)`,
          `Inactive (${Math.round(
            (Number(response.inactive) * 100) /
              (Number(response.inactive) + Number(response.active)),
          )}%)`,
        ],
        values: [response.active, response.inactive],
        marker: {
          colors: [Colors.lightPurple, Colors.kikorangiBlue],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};

export const transformSelfApprovedPullRequestsResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData: !response.critical && !response.usual,
    chartData: [
      {
        labels: [
          `Critical (${Math.round(
            (Number(response.critical) * 100) /
              (Number(response.critical) + Number(response.usual)),
          )}%)`,
          `Other (${Math.round(
            (Number(response.usual) * 100) /
              (Number(response.critical) + Number(response.usual)),
          )}%)`,
        ],
        values: [response.critical, response.usual],
        marker: {
          colors: [Colors.lightRed, Colors.active],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};

export const transformStaleRepositoriesResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData:
      !response.inact_repos_with_actions &&
      !response.inact_repos_without_actions,
    chartData: [
      {
        labels: [
          `Non Production (${Math.round(
            (Number(response.inact_repos_without_actions) * 100) /
              (Number(response.inact_repos_with_actions) +
                Number(response.inact_repos_without_actions)),
          )}%)`,
          `Production (${Math.round(
            (Number(response.inact_repos_with_actions) * 100) /
              (Number(response.inact_repos_with_actions) +
                Number(response.inact_repos_without_actions)),
          )}%)`,
        ],
        values: [
          response.inact_repos_without_actions,
          response.inact_repos_with_actions,
        ],
        marker: {
          colors: [Colors.lightPurple, Colors.kikorangiBlue],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};

export const transformUnprotectedDefaultBranchesResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData: !response.protected && !response.unprotected,
    chartData: [
      {
        labels: [
          `Branch Prot. Enabled (${Math.round(
            (Number(response.protected) * 100) /
              (Number(response.protected) + Number(response.unprotected)),
          )}%)`,
          `Without Protection (${Math.round(
            (Number(response.unprotected) * 100) /
              (Number(response.protected) + Number(response.unprotected)),
          )}%)`,
        ],
        values: [response.protected, response.unprotected],
        marker: {
          colors: [Colors.lightPurple, Colors.kikorangiBlue],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};

export const transformHighRiskOSSResponse = (
  response: Record<string, string>,
) => {
  return {
    isEmptyData: !response.high_risk && !response.high_risk_with_advisories,
    chartData: [
      {
        labels: [
          `Without Disclosure (${Math.round(
            ((Number(response.high_risk) -
              Number(response.high_risk_with_advisories)) *
              100) /
              Number(response.high_risk),
          )}%)`,
          `Security Disclosure (${Math.round(
            (Number(response.high_risk_with_advisories) * 100) /
              Number(response.high_risk),
          )}%)`,
        ],
        values: [
          Number(response.high_risk) -
            Number(response.high_risk_with_advisories),
          response.high_risk_with_advisories,
        ],
        marker: {
          colors: [Colors.lightPurple, Colors.kikorangiBlue],
        },
        type: 'pie' as 'pie',
        hole: 0.92,
        textinfo: 'none',
        domain: { x: [0, 0.5] },
      },
    ],
  };
};
