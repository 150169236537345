import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from '../../../../utils/sort-order/sort';
import { identityDetailsTabs } from '../../../../pages/identities/identity-details/constants';

export interface IdentitiesDetailsAlertsTable {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
}

export interface IdentitiesDetailsState {
  [identityDetailsTabs.ALERTS_TABLE]: IdentitiesDetailsAlertsTable;
}

const initialState = {
  [identityDetailsTabs.ALERTS_TABLE]: {
    activeSortedField: 'detection',
    activeSortedOrder: SortOrder.Desc,
  },
};
const identitiesDetailsSlice = createSlice({
  name: 'identitiesDetails',
  initialState,
  reducers: {
    setActiveSortedOrder: (
      state: IdentitiesDetailsState,
      action: PayloadAction<{
        tab: identityDetailsTabs;
        data: SortOrder;
      }>,
    ) => {
      state[action.payload.tab].activeSortedOrder = action.payload.data;
    },
    setActiveSortedField: (
      state: IdentitiesDetailsState,
      action: PayloadAction<{
        tab: identityDetailsTabs;
        data: string;
      }>,
    ) => {
      state[action.payload.tab].activeSortedField = action.payload.data;
    },
  },
});

export const { setActiveSortedField, setActiveSortedOrder } =
  identitiesDetailsSlice.actions;

export const identitiesDetailsReducer = identitiesDetailsSlice.reducer;
