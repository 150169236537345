import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Typography,
  Box,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import { useIdentitiesUserGroupsTeamsSelector } from 'store/modules/identities/identities.selector';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { paths } from 'constants/routes';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { Colors } from 'constants/colors';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { TEAMS_MODAL_TEAM_LINK } from 'constants/test-ids';

export const GroupTeamModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teams = useIdentitiesUserGroupsTeamsSelector();
  const metaData = useGetModalMetaData();
  const { org, source } = metaData;

  const isGroupTeamOpenModal = useIsPlainModalOpenSelector(
    PlainModals.GROUP_TEAM_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.GROUP_TEAM_MODAL));
  };

  const displayTeamName = (team: any) => {
    if (Object.keys(team)[0] === 'direct') {
      return 'direct';
    }
    if (team.team_name && team.team_name.length > 0) {
      return team.team_name;
    }
    return team.name || team.login || Object.keys(team)[0];
  };

  const returnTeamSlug = (team: any) => {
    return Object.keys(team)[0];
  };

  return (
    <ModalContainer
      isOpenModal={isGroupTeamOpenModal}
      title="Team Name"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
    >
      <TableContainer
        sx={{
          maxHeight: '750px',
          marginTop: '15px',
          border: `1px solid ${Colors.tableBorder}`,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead sx={{ height: '42px' }}>
            <TableRow>
              <StyledTableHeaderCell>Team Name</StyledTableHeaderCell>
              <StyledTableHeaderCell>Permissions</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams?.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.tableBorder,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.white,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tableCell,
                  },
                }}
              >
                <StyledTableBodyCell
                  sx={{
                    borderBottom: '0px',
                    borderTop: `1px solid ${Colors.cellBorder}`,
                  }}
                >
                  <Typography
                    onClick={() => {
                      if (displayTeamName(item) === 'direct') return;
                      handleCloseModal();
                      navigate(
                        `${paths.teamsDetails}?team=${org}:${returnTeamSlug(
                          item,
                        )}&source=${source}`,
                      );
                    }}
                    sx={{
                      fontSize: FontSizes.fontSize14,
                      fontFamily: fontSecondary,
                      textDecoration: 'none',
                      textAlign: 'left',
                      cursor:
                        displayTeamName(item) !== 'direct'
                          ? 'pointer'
                          : 'default',
                    }}
                    color={
                      displayTeamName(item) !== 'direct'
                        ? Colors.binge
                        : undefined
                    }
                    data-testid={TEAMS_MODAL_TEAM_LINK}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {displayTeamName(item)}
                      {displayTeamName(item) !== 'direct' && (
                        <ExternalIcon style={{ marginLeft: '10px' }} />
                      )}
                    </Box>
                  </Typography>
                </StyledTableBodyCell>
                <StyledTableBodyCell
                  align="left"
                  sx={{
                    borderBottom: '0px',
                    borderTop: `1px solid ${Colors.cellBorder}`,
                  }}
                >
                  {Object.values(item)[0]}
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
