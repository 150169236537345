import React from 'react';
import { Box, Typography } from '@mui/material';

import { StyledTooltip } from 'components/tooltip/tooltip';
import { fontPrimary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { SeverityIcon } from 'pages/dashboard/components/severity-icon/severity-icon';
import { mapColorsToSeverity } from 'pages/alerts/constants';
import { Colors } from 'constants/colors';

export const SeverityChip = ({
  severityScore,
  showText,
}: {
  severityScore: number;
  showText?: boolean;
}) => {
  let chipColor = '';
  switch (severityScore) {
    case 5:
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
            fontFamily: fontPrimary,
            fontSize: FontSizes.fontSize14,
            color: Colors.red,
          }}
        >
          <StyledTooltip title={showText ? '' : 'Critical'}>
            <SeverityIcon severityScore={5} iconSize={'20px'} />
          </StyledTooltip>
          {showText ? (
            <Typography sx={{ color: mapColorsToSeverity[5], ml: '2px' }}>
              Critical
            </Typography>
          ) : null}
        </Box>
      );
    case 4:
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
            fontFamily: fontPrimary,
            fontSize: FontSizes.fontSize14,
            color: chipColor,
          }}
        >
          <StyledTooltip title={showText ? '' : 'High'}>
            <SeverityIcon severityScore={4} iconSize={'20px'} />
          </StyledTooltip>
          {showText ? (
            <Typography sx={{ color: mapColorsToSeverity[4], ml: '2px' }}>
              High
            </Typography>
          ) : null}
        </Box>
      );
    case 3:
      return (
        <Box
          sx={{
            display: 'flex',
            fontFamily: fontPrimary,
            gap: '2px',
            fontSize: FontSizes.fontSize14,
            color: chipColor,
          }}
        >
          <StyledTooltip title={showText ? '' : 'Moderate'}>
            <SeverityIcon severityScore={3} iconSize={'20px'} />
          </StyledTooltip>
          {showText ? (
            <Typography sx={{ color: mapColorsToSeverity[3], ml: '2px' }}>
              Moderate
            </Typography>
          ) : null}
        </Box>
      );
    case 2:
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
            fontFamily: fontPrimary,
            fontSize: FontSizes.fontSize14,
            color: chipColor,
          }}
        >
          <StyledTooltip title={showText ? '' : 'Low'}>
            <SeverityIcon severityScore={2} iconSize={'20px'} />
          </StyledTooltip>
          {showText ? (
            <Typography sx={{ color: mapColorsToSeverity[2], ml: '2px' }}>
              Low
            </Typography>
          ) : null}
        </Box>
      );
    case 1:
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
            fontFamily: fontPrimary,
            fontSize: FontSizes.fontSize14,
            color: chipColor,
          }}
        >
          <StyledTooltip title={showText ? '' : 'Info'}>
            <SeverityIcon severityScore={1} iconSize={'20px'} />
          </StyledTooltip>
          {showText ? (
            <Typography sx={{ color: mapColorsToSeverity[1], ml: '2px' }}>
              Info
            </Typography>
          ) : null}
        </Box>
      );
    default:
      return <></>;
  }
};
