import React from 'react';
import { useDispatch } from 'react-redux';
import {
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { Colors } from 'constants/colors';
import { fontPrimary } from 'constants/font-family';

export const IdentitiesEmailsModal = () => {
  const dispatch = useDispatch();
  const modalMetaData = useGetModalMetaData() as unknown as {
    emails: string[];
  };

  const isIdentitiesEmailsOpenModal = useIsPlainModalOpenSelector(
    PlainModals.IDENTITIES_EMAILS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.IDENTITIES_EMAILS_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isIdentitiesEmailsOpenModal}
      title="Emails"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer sx={{ marginTop: '25px', maxHeight: '750px' }}>
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead>
            <TableRow
              sx={{
                fontFamily: fontPrimary,
              }}
            >
              <StyledTableHeaderCell>Email</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modalMetaData?.emails?.map((email, index) => (
              <TableRow
                key={index}
                sx={{
                  fontFamily: fontPrimary,
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.white,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tableCell,
                  },
                }}
              >
                <StyledTableBodyCell align="left">{email}</StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
