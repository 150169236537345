import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface SearchDataType {
  data: any[];
  english_phrase: string;
}
const initialState: { searchData: SearchDataType } = {
  searchData: {
    data: [],
    english_phrase: '',
  },
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setGlobalSearchData: (
      state: { searchData: SearchDataType },
      action: PayloadAction<SearchDataType>,
    ) => {
      state.searchData = action.payload;
    },
  },
});

export const { setGlobalSearchData } = globalSearchSlice.actions;

export const globalSearchReducer = globalSearchSlice.reducer;
