import React, { useEffect } from 'react';

export const useClickOutsideToClose = (
  ref: React.RefObject<HTMLDivElement>,
  handleClose?: () => void,
) =>
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // Check if click is inside component
      if (ref!.current!.contains(e.target as Node)) {
        return;
      }

      if ((e.target! as Element).classList.contains('MuiAutocomplete-option')) {
        return;
      }

      handleClose && handleClose();
    };
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClose, ref]);
