import { useDispatch } from 'react-redux';
import {
  FiltersTableName,
  setFiltersForTable,
} from 'store/modules/filters/filters.reducer';
import { useLocation } from 'react-router-dom';
import { useLocationsSelector } from 'store/modules/location/location.selector';
import { setLocationHistory } from 'store/modules/location/location.reducer';
import { useEffect } from 'react';
import { paths } from 'constants/routes';
import { useGetRoles } from 'store/modules/auth/auth.selectors';

interface Props {
  children: React.ReactElement;
}

const filtersToClearArray = [
  FiltersTableName.BRANCHES_LIST,
  FiltersTableName.ASSETS_REPOS_LIST,
  FiltersTableName.OPENSOURCE_REPOSITORIES_LIST,
];

export const RoutesWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const locationsArray = useLocationsSelector();
  const { pathname } = useLocation();
  const roles = useGetRoles();
  const developerOnly = roles?.length === 1 && roles[0] === 'developer';

  const dispatchEmptyFilters = (tableName: FiltersTableName) => {
    const filters = {};
    dispatch(
      setFiltersForTable({
        tableName,
        filters,
      }),
    );
  };

  const checkIfRouteNotApplicableForFilters = () => {
    if (pathname === paths.dashboard) {
      filtersToClearArray.forEach((item) => dispatchEmptyFilters(item));
    }
  };

  useEffect(() => {
    dispatch(setLocationHistory([...locationsArray.slice(-5), pathname]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  checkIfRouteNotApplicableForFilters();
  if (!developerOnly) return children;
  else if (developerOnly && pathname === '/identities/self') return children;
  else return null;
};
