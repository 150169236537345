import { Link } from 'react-router-dom';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';

import { Colors } from 'constants/colors';
import { Sizes } from 'constants/sizes';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: Sizes.menuWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: Colors.menuGradient,
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  background: Colors.menuGradient,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: Sizes.menuWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
}));

export const LogoContainer = styled('div')<{ textOpacity: number }>(
  ({ textOpacity }) => ({
    fontFamily: fontSecondary,
    display: 'flex',
    '& > span': {
      fontSize: FontSizes.fontSize10,
      color: Colors.white,
      opacity: textOpacity,
    },
  }),
);

export const Logo = styled('img')(() => ({
  //   height: '24px',
  //   marginRight: '8px',
  //   cursor: 'pointer',
}));

export const NavItemText = styled('span')<{
  opacity?: number;
  isActive?: boolean;
  connectorsExist?: boolean;
  noConnectorEnabled?: boolean;
}>(({ opacity, isActive, connectorsExist, noConnectorEnabled }) => ({
  fontSize: FontSizes.fontSize14,
  color: isActive
    ? Colors.menuText
    : !connectorsExist
    ? noConnectorEnabled
      ? Colors.menuIcon
      : Colors.whiteTransparent04
    : Colors.menuIcon,
  fontWeight: 500,
  opacity,
}));

export const NavChildItemText = styled(NavItemText)<{
  isActive: boolean;
  connectorsExist?: boolean;
  noConnectorEnabled?: boolean;
}>(({ isActive, connectorsExist, noConnectorEnabled }) => ({
  color: isActive ? Colors.binge : Colors.darkGray,
  fontFamily: '"Poppins", sans-serif',
}));

export const ExpandArrowContainer = styled('div')((isActive) => ({
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > svg': {
    fontSize: FontSizes.fontSize17,
  },
}));

export const MenuItemWrapper = styled('div')<{
  isBottomItem?: boolean;
}>(({ isBottomItem }) => ({
  marginTop: isBottomItem ? 'auto' : '10px',
  color: `${Colors.lightSilver} !important`,
}));

export const VersionInfo = styled('span')(() => ({
  color: Colors.white,
  paddingLeft: '23px',
  fontSize: FontSizes.fontSize10,
  fontFamily: fontSecondary,
  opacity: 0.3,
  position: 'absolute',
  left: '20px',
  bottom: '3px',
}));

export const CustomMenuIcon = styled(MenuItem)<{ isActive: boolean }>(
  ({ isActive }) => ({
    backgroundColor: isActive ? Colors.white : 'transparent',
    color: isActive ? Colors.white : Colors.whiteTransparent07,
    borderRadius: '5px',
    textAlign: 'center',
    padding: '5px',
    '&:hover': {
      backgroundColor: isActive ? Colors.white : Colors.transparent,
    },
  }),
);

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  cursor: 'none',
}));
