import { useAppSelector } from '../../hooks';
import { PlainModals, StatusModal } from './modals.reducer';

export const useIsPlainModalOpenSelector = (name: PlainModals): boolean =>
  useAppSelector(({ modals }) => modals[name]);

export const useGetModalMetaData = (): Partial<{
  [key: string]: any;
}> => useAppSelector(({ modals }) => modals.metaData);

export const useStatusModalOpenSelector = (): StatusModal =>
  useAppSelector(({ modals }) => modals.status_modal);
