import { TableColumnProps } from '../../../../components/table/table-component/table-component';

export const customPolicyTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Policy',
    withSort: true,
    position: 'left',
    noFilters: true,
  },
  {
    id: 'count',
    title: 'Detections',
    position: 'left',
    withSort: true,
  },
  {
    id: 'created_by',
    title: 'Created by',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    position: 'left',
  },
  {
    id: 'actions',
    title: 'Actions',
    position: 'left',
  },
  {
    id: 'options',
    title: '',
    position: 'left',
  },
];
