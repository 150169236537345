import { Box, styled } from '@mui/material';
import { Colors } from 'constants/colors';

export const LoaderContainer = styled(Box)<{ width?: string; height?: string }>(
  ({ width, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: height || '100%',
    width: width || '100%',
    background: Colors.white,
  }),
);
