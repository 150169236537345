import React, { ReactNode } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { LoaderContainer } from './styles';

interface Props {
  isLoading: boolean;
  isError: boolean;
  height?: string;
  width?: string;
  children: ReactNode;
}

export const ContainerWithLoader = ({
  children,
  isError,
  height,
  width,
  isLoading,
}: Props) => {
  const renderContent = () => {
    if (isLoading) {
      return (
        <LoaderContainer height={height} width={width}>
          <CircularProgress sx={{ color: Colors.blackPearl }} size={30} />
        </LoaderContainer>
      );
    }

    if (isError) {
      return (
        <LoaderContainer height={height} width={width}>
          <Typography
            sx={{
              fontSize: FontSizes.fontSize17,
              opacity: '0.2',
              color: Colors.blackPearl,
            }}
          >
            Error
          </Typography>
        </LoaderContainer>
      );
    }

    return <>{children}</>;
  };

  return <>{renderContent()}</>;
};
