import React, { useEffect, useState } from 'react';
import { isEqual, uniqWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { FilterItem, MultiFilter } from 'components/multi-filter/multi-filter';

import { SortOrder } from 'utils/sort-order/sort';
import { TableColumnProps } from 'components/table/table-component/table-component';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';
import { setPageNumber } from 'store/modules/pagination/pagination.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import {
  Filter,
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
  initialState as DefaultState,
  setTablePageNumber,
  setSearchBy,
} from 'store/modules/selected-column/selected-column.reducer';

interface Props {
  tableId: string;
  FilterList: FilterItem[];
  setActiveSortedField: any;
  setActiveSortedOrder: any;
  tableStructure: TableColumnProps[];
  isLast: boolean;
}

export const GenericFilter = ({
  isLast,
  tableId,
  FilterList,
  setActiveSortedField,
  setActiveSortedOrder,
  tableStructure,
}: Props) => {
  const dispatch = useDispatch();
  const selectedColumnFilter = useSelectedColumnSelector(tableId);
  const {
    tableFilterChips,
    applicableFilters,
    activeSortedField,
    activeSortedOrder,
    previousColumn,
    searchBy,
  } = selectedColumnFilter;
  let prevHighCols = selectedColumnFilter?.highlightColumns;
  const {
    sorting_field,
    sorting_order,
    search_field,
    search_query,
    searchChips,
    filterTitles: identityTitles,
    ...filterChips
  } = tableFilterChips;
  const [filtersObj, setFiltersObj] = useState<Filter>(filterChips);
  const [displayFilters, setDisplayFilters] =
    useState<FilterItem[]>(FilterList);
  const [currentOrder, setCurrentOrder] = useState('');
  const prevSearch =
    searchBy.find(
      (element) => element.id === selectedColumnFilter?.previousColumn,
    )?.query || '';
  const [search, setSearch] = useState(prevSearch);

  const withSearch =
    tableStructure.findIndex(
      (column) => column.id === previousColumn && column.withSearch,
    ) !== -1;
  useEffect(() => {
    setDisplayFilters(() =>
      FilterList.filter((item) => {
        if (!item?.filterColumns) return false;
        return (
          item?.filterColumns?.findIndex(
            (col) => col === selectedColumnFilter?.previousColumn,
          ) !== -1
        );
      }),
    );
    if (activeSortedField === selectedColumnFilter?.previousColumn) {
      setCurrentOrder(activeSortedOrder === '+' ? 'asc' : 'des');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterHandler = (
    filterName: string,
    filterValue?: string | boolean | string[],
  ) =>
    setFiltersObj({
      ...filtersObj,
      [filterName]:
        filtersObj[filterName] === filterValue ? undefined : filterValue,
    });

  const showResultsHandler = () => {
    const totalFilters: Filter = { ...tableFilterChips, ...filtersObj };
    const mutableFilterChips = { ...tableFilterChips };
    let { searchChips } = mutableFilterChips;
    if (search !== prevSearch) {
      applySearch();
      if (search === '') {
        if (Array.isArray(searchChips))
          searchChips = searchChips.filter(
            (chip) => chip.id !== selectedColumnFilter?.previousColumn,
          );
      }
      if (search) {
        const newChip = {
          id: selectedColumnFilter?.previousColumn || '',
          title:
            tableStructure.find(
              (item) => item?.id === selectedColumnFilter?.previousColumn,
            )?.title || '',
          query: search,
        };
        if (
          prevHighCols.findIndex(
            (col) => col.id === selectedColumnFilter?.previousColumn,
          ) === -1
        )
          prevHighCols = [
            ...prevHighCols,
            {
              id: selectedColumnFilter?.previousColumn || '',
              title:
                tableStructure.find(
                  (item) => item?.id === selectedColumnFilter?.previousColumn,
                )?.title || '',
            },
          ];
        if (Array.isArray(searchChips)) {
          const index = searchChips.findIndex((srch) => srch.id === newChip.id);
          if (index !== -1) {
            const mutableSearchChips = [...searchChips];
            mutableSearchChips[index] = newChip;
            searchChips = mutableSearchChips;
          } else searchChips = [...searchChips, newChip];
        } else searchChips = [newChip];
      }
    }
    const filters =
      selectedColumnFilter.previousTable ===
      TableNamesOfFilters.IDENTITIES_USERS_LIST
        ? {
            account_status: totalFilters.account_status
              ? totalFilters.account_status === 'true'
              : undefined,
            team_member: totalFilters.hasOwnProperty('team_member')
              ? totalFilters.team_member
              : undefined,
            internal_account:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'false'
                ? true
                : undefined,
            external_account:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'true'
                ? true
                : undefined,
            unknown:
              totalFilters.identity_status &&
              totalFilters.identity_status === 'unknown'
                ? true
                : undefined,
            account_type: totalFilters.account_type
              ? totalFilters.account_type
              : undefined,
            highest_severity: totalFilters.highest_severity
              ? totalFilters.highest_severity
              : undefined,
            only_watched: totalFilters.hasOwnProperty('only_watched')
              ? totalFilters.only_watched
              : undefined,
            only_hidden: totalFilters.hasOwnProperty('only_hidden')
              ? totalFilters.only_hidden
              : undefined,
            is_admin: totalFilters.hasOwnProperty('is_admin')
              ? totalFilters.is_admin
              : undefined,
            source: totalFilters.source ? totalFilters.source : undefined,
          }
        : totalFilters;

    const previousFilterTitles =
      typeof mutableFilterChips?.filterTitles === 'object'
        ? [...mutableFilterChips?.filterTitles]
        : [];
    Object.keys(filtersObj)
      .filter((colId) => typeof filtersObj[colId] === 'undefined')
      .forEach((colId) => {
        delete filtersObj[colId];
        delete mutableFilterChips[colId];
        const indexToRemove = previousFilterTitles.findIndex(
          (filter) => filter.id === colId,
        );
        if (indexToRemove !== -1) {
          previousFilterTitles.splice(indexToRemove, 1);
        }
      });

    const filterTitles = Object.keys(filtersObj).map((colId) => {
      const title =
        FilterList.find((item) => item?.filterId === colId)?.title || '';
      return { id: colId, title, column: selectedColumnFilter?.previousColumn };
    });

    const finalFilterTitles = uniqWith(
      [...previousFilterTitles, ...filterTitles],
      (arrVal, othVal) => arrVal.id === othVal.id,
    );
    dispatch(
      setFilterChips({
        table: tableId,
        tableFilterChips: {
          ...mutableFilterChips,
          ...filtersObj,
          searchChips: searchChips,
          filterTitles: finalFilterTitles,
        },
      }),
    );
    if (
      isEqual(filters, {
        ...applicableFilters,
        searchChips: filters.searchChips,
      })
    )
      if (currentOrder === '') {
        if (
          mutableFilterChips?.sorting_field ===
          selectedColumnFilter?.previousColumn
        ) {
          applySort('');
          dispatch(
            setFilterChips({
              table: tableId,
              tableFilterChips: {
                ...mutableFilterChips,
                ...filtersObj,
                searchChips: searchChips,
                filterTitles: finalFilterTitles,
                sorting_order: undefined,
                sorting_field: '',
              },
            }),
          );
        }
        if (search === prevSearch) return;
      }
    if (
      selectedColumnFilter.previousTable ===
      TableNamesOfFilters.IDENTITIES_USERS_LIST
    )
      dispatch(
        setApplicableFilters({
          table: tableId,
          applicableFilters: { ...filters },
        }),
      );
    else
      dispatch(
        setApplicableFilters({
          table: tableId,
          applicableFilters: {
            ...filters,
            filterTitles: finalFilterTitles,
            sorting_order:
              currentOrder !== ''
                ? currentOrder
                : activeSortedOrder === '+'
                ? 'asc'
                : 'des',
            sorting_field:
              currentOrder !== ''
                ? selectedColumnFilter?.previousColumn
                : activeSortedField,
          },
        }),
      );

    if (currentOrder !== '') {
      if (finalFilterTitles?.length < 1) {
        dispatch(
          setHighlightColumns({
            table: tableId,
            highlightColumns: [
              {
                id: selectedColumnFilter?.previousColumn || '',
                title:
                  tableStructure.find(
                    (item) => item?.id === selectedColumnFilter?.previousColumn,
                  )?.title || '',
              },
            ],
          }),
        );
      } else if (
        mutableFilterChips?.sorting_field !==
        selectedColumnFilter?.previousColumn
      ) {
        const requiredCols = finalFilterTitles.filter(
          (item) => item?.column !== mutableFilterChips?.sorting_field,
        );
        dispatch(
          setHighlightColumns({
            table: tableId,
            highlightColumns: [...requiredCols],
          }),
        );
      }
      applySort(currentOrder ? selectedColumnFilter?.previousColumn : '');
      dispatch(
        setFilterChips({
          table: tableId,
          tableFilterChips: {
            ...mutableFilterChips,
            ...filtersObj,
            searchChips: searchChips,
            filterTitles: finalFilterTitles,
            sorting_order: currentOrder !== '' ? currentOrder : undefined,
            sorting_field: selectedColumnFilter?.previousColumn,
          },
        }),
      );
      if (
        !selectedColumnFilter.highlightColumns.find(
          (col) => col.title === selectedColumnFilter.previousColumn,
        )
      )
        dispatch(
          setHighlightColumns({
            table: tableId,
            highlightColumns: [
              ...prevHighCols,
              {
                id: selectedColumnFilter?.previousColumn,
                title:
                  tableStructure.find(
                    (item) => item?.id === selectedColumnFilter?.previousColumn,
                  )?.title || '',
              },
            ],
          }),
        );
    } else {
      dispatch(
        setHighlightColumns({
          table: tableId,
          highlightColumns: [
            ...prevHighCols,
            {
              id: selectedColumnFilter?.previousColumn,
              title:
                tableStructure.find(
                  (item) => item?.id === selectedColumnFilter?.previousColumn,
                )?.title || '',
            },
          ],
        }),
      );
    }
    dispatch(setPageNumber(1));
    if (selectedColumnFilter)
      dispatch(
        setTablePageNumber({
          table: tableId,
          page: 1,
        }),
      );
  };

  const applySort = (field: string) => {
    dispatch(
      setActiveSortedField({
        table: tableId,
        activeSortedField: field
          ? field
          : DefaultState[tableId].activeSortedField,
      }),
    );
    dispatch(
      setActiveSortedOrder({
        table: tableId,
        activeSortedOrder:
          currentOrder === 'asc' ? SortOrder.Asc : SortOrder.Desc,
      }),
    );
  };

  const applySearch = (field = search) => {
    if (field !== '')
      dispatch(
        setSearchBy({
          table: tableId,
          searchBy: [
            ...searchBy.filter(
              (srch) => srch.id !== selectedColumnFilter?.previousColumn,
            ),
            {
              id: selectedColumnFilter.previousColumn,
              query: field,
            },
          ],
        }),
      );
    else if (field === '')
      dispatch(
        setSearchBy({
          table: tableId,
          searchBy: searchBy.filter(
            (srch) => srch.id !== selectedColumnFilter?.previousColumn,
          ),
        }),
      );
  };

  const removeFiltersHandler = () => {
    applySearch('');
    const filters = { ...tableFilterChips };
    const filterObj = { ...filtersObj };
    const { filterTitles, sorting_field } = filters;
    let { searchChips } = filters;
    const titles: any[] = [];
    if (typeof filterTitles === 'object') {
      titles.push(
        ...filterTitles.filter(
          (title) => title.column === selectedColumnFilter?.previousColumn,
        ),
      );
      titles.forEach((title) => {
        if (filters[title.id]) {
          delete filters[title.id];
          delete filterObj[title.id];
        }
      });
    }
    if (typeof sorting_field === 'string') {
      if (sorting_field === selectedColumnFilter?.previousColumn) {
        delete filters['sorting_field'];
        delete filters['sorting_order'];
      }
    }
    const highlightColumns = selectedColumnFilter.highlightColumns?.filter(
      (column) => column.id !== selectedColumnFilter?.previousColumn,
    );

    if (Array.isArray(searchChips))
      searchChips = searchChips.filter(
        (srch) => srch.id !== selectedColumnFilter?.previousColumn,
      );

    setFiltersObj(filterObj);
    dispatch(
      setFilterChips({
        table: tableId,
        tableFilterChips: {
          ...filters,
          searchChips,
          filterTitles:
            typeof filterTitles === 'object'
              ? filterTitles.filter(
                  (title) =>
                    title.column !== selectedColumnFilter?.previousColumn,
                )
              : undefined,
        },
      }),
    );
    dispatch(
      setApplicableFilters({
        table: tableId,
        applicableFilters: {
          ...filters,
          filterTitles:
            typeof filterTitles === 'object'
              ? filterTitles.filter(
                  (title) =>
                    title.column !== selectedColumnFilter?.previousColumn,
                )
              : undefined,
        },
      }),
    );
    if (sorting_field === selectedColumnFilter?.previousColumn) {
      applySort(DefaultState[tableId]?.activeSortedField);
      setCurrentOrder('');
    }
    dispatch(
      setHighlightColumns({
        table: tableId,
        highlightColumns: highlightColumns,
      }),
    );
  };

  return (
    <MultiFilter
      isLast={isLast}
      tableId={tableId}
      showResultsHandler={showResultsHandler}
      setFilterHandler={setFilterHandler}
      filtersObj={filtersObj}
      removeFiltersHandler={removeFiltersHandler}
      filters={displayFilters}
      currentOrder={currentOrder}
      setCurrentOrder={setCurrentOrder}
      {...(withSearch ? { setSearch, search } : {})}
    />
  );
};
