import { InfoComponent } from 'components/info-component/info-component';
import { HeaderBackButton } from 'components/page-components/page-header/header-back-button';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PRSecretScanInfoStructure } from '../constants';
import { PR_SECRETS_SCAN_INFO_COMPONENT } from 'constants/test-ids';
import { Box } from '@mui/material';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { PRSecretsRow } from '../components/pr-secrets-row';

export const PRSecretsDetails = () => {
  const apiContext = useContext(ApiContext);
  const { org, repo, pr_number, check_run_id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [infoData, setInfoData] = useState({});

  const { data, isLoading, isError } =
    apiContext.baseApi.useGetPRSecretScanDetailsQuery({
      org: org!,
      repo: repo!,
      pr_number: parseInt(pr_number!),
      check_run_id: parseInt(check_run_id!),
    });

  const groupByFiles = () => {
    const files = Array.from(
      new Set(data?.data?.map((item: any) => item.file_name)),
    );
    let scanData: any = [];
    files.forEach((file) => {
      const filteredData = data.data.filter(
        (item: any) => item.file_name === file,
      );
      let fileData: any = {};
      fileData['file'] = [file];
      fileData['data'] = filteredData;
      scanData.push(fileData);
    });
    setTableData(scanData);
  };

  useEffect(() => {
    if (data?.data) {
      groupByFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.data) {
      let info: any = { org, repo, pr_number, check_run_id };
      const source = data?.data?.map((item: any) => item?.source);
      if (source) info['source'] = source[0];
      const type = data?.data?.map((item: any) => item?.type);
      if (type) info['type'] = type[0];
      setInfoData(info);
    }
  }, [data, org, repo, pr_number, check_run_id]);

  return (
    <>
      <HeaderBackButton />
      <InfoComponent
        data={infoData}
        isLoading={isLoading}
        isError={isError}
        infoStructure={PRSecretScanInfoStructure}
        dataTestId={PR_SECRETS_SCAN_INFO_COMPONENT}
      />
      <ContainerWithLoader isLoading={isLoading} isError={isError}>
        <Box sx={{ width: '100%', overflowY: 'auto', marginTop: '20px' }}>
          {!!tableData.length &&
            tableData.map((scan: any, index) => (
              <PRSecretsRow scanData={scan} index={index} />
            ))}
        </Box>
      </ContainerWithLoader>
    </>
  );
};
