import { Box, styled, Tab, Tabs } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
    background: 'transparent !important',
  },
  '& .MuiTabs-flexContainer': {
    gap: '10px',
  },
  minHeight: '34px',
}));

export const StyledTab = styled(Tab)(() => ({
  color: Colors.blackPearl,
  border: `1px solid ${Colors.tableBorder}`,
  textTransform: 'none',
  fontFamily: fontSecondary,
  fontSize: FontSizes.fontSize14,
  lineHeight: '14px',
  minHeight: '0px',
  borderRadius: '5px',
  padding: '10px 15px',
  background: Colors.tableHeader,
  '&.Mui-selected': {
    color: Colors.white,
    background: Colors.tabGradient,
    border: 'none',
    boxShadow: `10px 0px 25px 0px ${Colors.tableHeader}`,
  },
}));

export const StyledTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: '15px' }}>{children}</Box>}
    </Box>
  );
};
