import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Package Name',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'path',
    title: 'Path',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'direct',
    title: 'Direct',
    position: 'left',
  },
  {
    id: 'importer',
    title: 'Importer(s)',
    position: 'left',
  },
  {
    id: 'import_at',
    title: 'Imported Date',
    position: 'left',
  },
  {
    id: 'instances',
    title: 'Instances',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'version',
    title: 'Version',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'severity',
    title: 'Vulnerability',
    position: 'left',
  },
];
