import { createTheme } from '@mui/material/styles';
import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

// A custom theme for this app
export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: '"Poppins", sans-serif',
      color: Colors.blackPearl,
    },
    h1: {
      fontSize: FontSizes.fontSize12,
      color: Colors.blackPearl,
      fontWeight: 500,
      marginBottom: '20px',
    },
    h3: {
      fontSize: FontSizes.fontSize16,
      lineHeight: '24px',
      color: Colors.blackPearl,
      fontWeight: 600,
    },
    body1: {
      fontSize: FontSizes.fontSize14,
      lineHeight: '21px',
      color: Colors.blackPearl,
      fontWeight: 400,
    },
  },
  palette: {},
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: Colors.blackPearl,
          fontSize: FontSizes.fontSize14,
          borderColor: Colors.sectionBorder,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          color: Colors.blackPearl,
          fontSize: FontSizes.fontSize11,
        },
      },
    },
  },
});
