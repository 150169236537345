import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface TeamsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  searchQuery: string;
  activeTab: number;
}

const initialState: TeamsState = {
  activeSortedField: 'team_risk_score',
  activeSortedOrder: SortOrder.Desc,
  searchQuery: '',
  activeTab: 0,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: TeamsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: TeamsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setSearchQuery: (state: TeamsState, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setActiveTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setActiveSortedField,
  setActiveSortedOrder,
  setSearchQuery,
  setActiveTab,
} = teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;
