import React, { FC, ReactNode } from 'react';
import { defaultBaseApi } from 'store/apis/base';

interface IAuthContext {
  baseApi: typeof defaultBaseApi;
}
export const ApiContext = React.createContext<IAuthContext>({
  baseApi: defaultBaseApi,
});

interface Props {
  children?: ReactNode;
  baseApi: typeof defaultBaseApi;
}

export const ApiProvider: FC<Props> = ({ children, baseApi }) => {
  return (
    <ApiContext.Provider
      value={{
        baseApi,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
