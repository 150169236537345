import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const unusedAccessTableStructure: TableColumnProps[] = [
  {
    id: 'checkbox',
    title: '',
    position: 'left',
  },
  {
    id: 'repo',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Accessed',
    withSort: true,
    noFilters: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'highest_permission',
    title: 'Highest Permissions',
    withSort: true,
    position: 'left',
  },
  {
    id: 'permission_used',
    title: 'Permissions Used',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'scm_source',
    title: 'Source',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'remediate',
    title: '',
    position: 'center',
  },
];

export const unusedAccessFilterList: FilterItem[] = [
  {
    title: 'Critical',
    filterId: 'is_critical',
    filterColumns: ['repo'],
    props: [
      {
        label: 'Critical',
        value: true,
      },
      {
        label: 'Not Critical',
        value: false,
      },
    ],
  },
  {
    title: 'Highest Permission',
    filterId: 'permission',
    filterColumns: ['highest_permission'],
    props: [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Maintain',
        value: 'maintain',
      },
      {
        label: 'Write',
        value: 'write',
      },
      {
        label: 'Triage',
        value: 'triage',
      },
      {
        label: 'Read',
        value: 'read',
      },
    ],
  },
];
