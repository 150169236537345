import { FilterItem } from 'components/multi-filter/multi-filter';

export const AssetFilterList = (connections: string[]): FilterItem[] => {
  return [
    {
      title: 'Critical',
      filterId: 'critical',
      filterColumns: ['name'],
      props: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    {
      title: 'Risk Rating',
      filterId: 'highest_severity',
      filterColumns: ['highest_severity'],
      props: [
        {
          label: 'Critical',
          value: '5',
        },
        {
          label: 'High',
          value: '4',
        },
        {
          label: 'Medium',
          value: '3',
        },
        {
          label: 'Low',
          value: '2',
        },
        {
          label: 'None',
          value: '0',
        },
      ],
    },
    {
      title: 'Status',
      filterId: 'status',
      filterColumns: ['status'],
      props: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Stale',
          value: 'inactive',
        },
        {
          label: 'Archived',
          value: 'archived',
        },
      ],
    },
    {
      title: 'Production',
      filterId: 'only_production',
      filterColumns: ['name'],
      props: [
        {
          label: 'Production',
          value: true,
        },
        {
          label: 'Non Production',
          value: false,
        },
      ],
    },
    {
      title: 'Watched',
      filterId: 'only_watched',
      filterColumns: ['name'],
      props: [
        {
          label: 'Watched',
          value: true,
        },
        {
          label: 'Not Watched',
          value: false,
        },
      ],
    },
    {
      title: 'Hidden',
      filterId: 'only_hidden',
      filterColumns: ['name'],
      props: [
        {
          label: 'Hidden',
          value: true,
        },
        {
          label: 'Not Hidden',
          value: false,
        },
      ],
    },
    {
      title: 'Visibility',
      filterId: 'visibility',
      filterColumns: ['visibility'],
      props: [
        {
          label: 'Public',
          value: 'public',
        },
        {
          label: 'Internal',
          value: 'internal',
        },
        {
          label: 'Private',
          value: 'private',
        },
      ],
    },
    {
      title: 'Source',
      filterId: 'source',
      filterColumns: ['source'],
      props: [
        {
          label: 'GitHub',
          value: 'GitHub',
        },
        {
          label: 'GitLab',
          value: 'GitLab',
        },
        {
          label: 'BitBucket',
          value: 'BitBucket',
        },
        {
          label: 'AzureDevOps',
          value: 'AzureDevOps',
        },
        {
          label: 'JFrog',
          value: 'JFrog',
        },
      ].filter(
        (connector) =>
          connections.findIndex((item) => item === connector?.label) !== -1,
      ),
    },
  ];
};

export const ArtifactFilterList = (connections: string[]): FilterItem[] => {
  return [
    {
      title: 'Critical',
      filterId: 'critical',
      filterColumns: ['name'],
      props: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    {
      title: 'Risk Rating',
      filterId: 'highest_severity',
      filterColumns: ['highest_severity'],
      props: [
        {
          label: 'Critical',
          value: '5',
        },
        {
          label: 'High',
          value: '4',
        },
        {
          label: 'Medium',
          value: '3',
        },
        {
          label: 'Low',
          value: '2',
        },
        {
          label: 'None',
          value: '0',
        },
      ],
    },
    {
      title: 'Status',
      filterId: 'status',
      filterColumns: ['status'],
      props: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Stale',
          value: 'inactive',
        },
        {
          label: 'Archived',
          value: 'archived',
        },
      ],
    },
    {
      title: 'Production',
      filterId: 'only_production',
      filterColumns: ['name'],
      props: [
        {
          label: 'Production',
          value: true,
        },
        {
          label: 'Non Production',
          value: false,
        },
      ],
    },
    {
      title: 'Watched',
      filterId: 'only_watched',
      filterColumns: ['name'],
      props: [
        {
          label: 'Watched',
          value: true,
        },
        {
          label: 'Not Watched',
          value: false,
        },
      ],
    },
    {
      title: 'Hidden',
      filterId: 'only_hidden',
      filterColumns: ['name'],
      props: [
        {
          label: 'Hidden',
          value: true,
        },
        {
          label: 'Not Hidden',
          value: false,
        },
      ],
    },
    {
      title: 'Visibility',
      filterId: 'visibility',
      filterColumns: ['visibility'],
      props: [
        {
          label: 'Public',
          value: 'public',
        },
        {
          label: 'Internal',
          value: 'internal',
        },
        {
          label: 'Private',
          value: 'private',
        },
      ],
    },
    {
      title: 'Source',
      filterId: 'source',
      filterColumns: ['source'],
      props: [
        {
          label: 'JFrog',
          value: 'JFrog',
        },
      ].filter(
        (connector) =>
          connections.findIndex((item) => item === connector?.label) !== -1,
      ),
    },
  ];
};
