import React from 'react';
import { Typography } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import {
  DatesContainer,
  DatesPopupContainer,
  ButtonsContainer,
  DateValue,
  DateRangeInputContainer,
  StyledDatePicker,
} from './styles';
import { FontSizes } from 'constants/font-sizes';
import { StyledButton } from '../../button/button';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import {
  APPLY_DATE_RANGE_INPUT,
  CANCEL_DATE_RANGE_INPUT,
  CUSTOM_DATE_RANGE_PICKER,
} from 'constants/test-ids';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  onSubmitHandler: () => void;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  resetDates: () => void;
}

export const DateRangeInput = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  setEndDate,
  setStartDate,
  onSubmitHandler,
  isToggle,
  setIsToggle,
  resetDates,
}: Props) => {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DateRangeInputContainer>
      {isToggle && (
        <DatesPopupContainer>
          <DatesContainer>
            <DateValue>
              <Typography
                sx={{
                  fontSize: FontSizes.fontSize14,
                  color: Colors.blackPearl,
                  fontFamily: fontSecondary,
                  lineHeight: '22px',
                  textAlign: 'center',
                  flexGrow: 1,
                }}
              >
                {startDate ? dayjs(startDate).format('MMM DD') : 'Start Date'}
              </Typography>
            </DateValue>
            -
            <DateValue>
              <Typography
                sx={{
                  fontSize: FontSizes.fontSize14,
                  color: Colors.blackPearl,
                  fontFamily: fontSecondary,
                  lineHeight: '22px',
                  textAlign: 'center',
                  flexGrow: 1,
                }}
              >
                {endDate ? dayjs(endDate).format('MMM DD') : 'End Date'}
              </Typography>
            </DateValue>
          </DatesContainer>
          <StyledDatePicker
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate || new Date()}
            minDate={minDate}
            selectsRange
            inline
            data-testid={CUSTOM_DATE_RANGE_PICKER}
          />
          <ButtonsContainer>
            <StyledButton
              variant="outlined"
              color="info"
              size="medium"
              sx={{
                fontSize: '14px !important',
                marginRight: '15px',
                padding: '8px 10px',
                height: '38px !important',
                width: '80px',
                fontFamily: fontSecondary,
                border: `1px solid ${Colors.blackPearl} !important`,
                color: `${Colors.blackPearl} !important`,
                borderRadius: '5px',
              }}
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                setIsToggle(false);
                resetDates();
              }}
              data-testid={CANCEL_DATE_RANGE_INPUT}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="secondary"
              size="medium"
              sx={{
                fontSize: '14px !important',
                padding: '8px 10px',
                height: '38px !important',
                width: '80px',
                fontFamily: fontSecondary,
                borderRadius: '5px',
              }}
              onClick={() => {
                onSubmitHandler();
                setIsToggle(false);
              }}
              data-testid={APPLY_DATE_RANGE_INPUT}
            >
              Apply
            </StyledButton>
          </ButtonsContainer>
        </DatesPopupContainer>
      )}
    </DateRangeInputContainer>
  );
};
