import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { previousLocations: string[] } = {
  previousLocations: [''],
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationHistory: (
      state: { previousLocations: string[] },
      action: PayloadAction<string[]>,
    ) => {
      state.previousLocations = action.payload;
    },
  },
});

export const { setLocationHistory } = locationsSlice.actions;

export const locationsReducer = locationsSlice.reducer;
