import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { ReactComponent as HighSeverityIcon } from 'assets/icons/severity-high.svg';
import { ReactComponent as MediumSeverityIcon } from 'assets/icons/severity-medium.svg';
import { ReactComponent as LowSeverityIcon } from 'assets/icons/severity-low.svg';
import { ReactComponent as CriticalSeverityIcon } from 'assets/icons/severity-critical.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from 'constants/colors';
import {
  ExtraCellsObject,
  TableComponent,
} from 'components/table/table-component/table-component';
import {
  PR_SECRET_SCAN_DETAILS_TABLE,
  TRUFFLEHOG_SCANS_ACTIONS_DROPDOWN,
} from 'constants/test-ids';
import { PRSecretScanDetailsTableStructure } from '../constants';
import { PopoverSelect } from 'components/popover-select/popover-select';
import { useDispatch } from 'react-redux';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/toast/toast-message';
import {
  PlainModals,
  openModalByNameAction,
} from 'store/modules/modals/modals.reducer';
import { StyledTooltip } from 'components/tooltip/tooltip';
import { useParams } from 'react-router-dom';

export const PRSecretsRow = ({
  scanData,
  index,
}: {
  scanData: any;
  index: number;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { org, repo, pr_number, check_run_id } = useParams();
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const [tableData, setTableData] = useState([]);
  const [getPrSecretData] =
    apiContext.baseApi.useLazyGetPRSecretScanDetailsQuery();

  useEffect(() => {
    const order = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO'];
    const tableData = scanData.data.sort(
      (a: any, b: any) => order.indexOf(a.severity) - order.indexOf(b.severity),
    );
    setTableData(tableData);
  }, [scanData]);
  const [updateScanStatus] =
    apiContext.baseApi?.useUpdateTrufflehogScanStatusMutation();

  const updateStatus = async (payload: {
    status: string;
    result_id: string;
  }) => {
    try {
      await updateScanStatus(payload);
      getPrSecretData({
        org: org!,
        repo: repo!,
        pr_number: parseInt(pr_number!),
        check_run_id: parseInt(check_run_id!),
      });
      toast(
        <ToastMessage type="success" title="Successfully Updated the Status" />,
      );
    } catch (e) {
      toast(
        <ToastMessage
          type="error"
          title="Error occureed during updating the status"
        />,
      );
    }
  };

  const RenderSeverity = ({ item }: any) => {
    const severities = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO'];
    return (
      <Box display={'flex'} gap={'10px'}>
        {severities.map((level) => {
          if (item.severity[level])
            return (
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                {item.severity[level]} {RenderSeverityCell({ severity: level })}
              </Box>
            );
          return <></>;
        })}
      </Box>
    );
  };

  const RenderFile = (item: any) => {
    return (
      <>
        {item.file.map((file: string, index: number) => (
          <StyledTooltip title={file}>
            <span>
              {index !== 0 && ', '}
              {file.split('/').pop()}
            </span>
          </StyledTooltip>
        ))}
      </>
    );
  };

  const RenderSeverityCell = (item: any) => {
    if (typeof item.severity === 'object')
      return <RenderSeverity item={item} />;
    let chipColor = '';
    let chipBackground = '';
    let chipIcon: JSX.Element;
    const openScanDetailsModal = () => {
      dispatch(
        openModalByNameAction({
          type: PlainModals.PR_SECRETS_MODAL,
          metaData: {
            scan: item,
          },
        }),
      );
    };
    switch (item.severity) {
      case 'CRITICAL':
        chipColor = '#F85455';
        chipBackground = 'rgba(248, 84, 85, 0.2);';
        chipIcon = (
          <CriticalSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'HIGH':
        chipColor = '#F77A63';
        chipBackground = 'rgba(247, 122, 99, 0.2)';
        chipIcon = (
          <HighSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'MEDIUM':
        chipColor = '#FF9D2B';
        chipBackground = 'rgba(255, 157, 43, 0.2)';
        chipIcon = (
          <MediumSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'LOW':
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      default:
        chipColor = '#FDD60D';
        chipBackground = 'rgba(253, 214, 13, 0.2)';
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
    }
    return item.severity ? (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        icon={chipIcon}
        onClick={item?.CauseMetadata ? openScanDetailsModal : undefined}
        label={item.severity}
        variant="outlined"
      />
    ) : (
      item.severity
    );
  };

  const RenderActionCell = (item: any) => {
    return (
      <PopoverSelect
        index={'3'}
        dataTestId={TRUFFLEHOG_SCANS_ACTIONS_DROPDOWN}
        disabled={false}
        buttonTitle={item?.['_ids_status']}
        menuItems={[
          {
            id: `scan-status-open`,
            title: 'Open',
            onClick: () =>
              updateStatus({ status: 'Open', result_id: item?.result_id }),
          },
          {
            id: `scan-status-false-positive`,
            title: 'False Positive',
            onClick: () =>
              updateStatus({
                status: 'False Positive',
                result_id: item?.result_id,
              }),
          },
          {
            id: `scan-status-fixed`,
            title: 'Fixed',
            onClick: () =>
              updateStatus({ status: 'Fixed', result_id: item?.result_id }),
          },
          {
            id: `scan-status-in-progress`,
            title: 'In Progress',
            onClick: () =>
              updateStatus({
                status: 'In Progress',
                result_id: item?.result_id,
              }),
          },
        ]}
      />
    );
  };

  // const RenderFileCell = (item: any) => {
  //   return (
  //     <StyledTooltip title={item.file}>
  //       <Typography>{item.file.split('/').pop()}</Typography>
  //     </StyledTooltip>
  //   );
  // };

  const extraCellsRenderObj: ExtraCellsObject = {
    severity: RenderSeverityCell,
    action: RenderActionCell,
    // file_name: RenderFileCell,
  };

  return (
    <Accordion
      key={index}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: Colors.white }} />}
        aria-controls={`panel${index + 1}bh-content`}
        id={`panel${index + 1}bh-header`}
        sx={{
          backgroundColor: index % 2 === 0 ? Colors.lightTiber : Colors.tiber,
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '&:hover': {
            backgroundColor: Colors.blackPearl,
          },
        }}
      >
        <Typography mr={2}>{RenderSeverityCell(scanData)}</Typography>
        <Typography>{RenderFile(scanData)}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: Colors.sectionBackground,
          color: Colors.white,
        }}
      >
        <TableComponent
          tableId={PR_SECRET_SCAN_DETAILS_TABLE}
          isListLoading={false}
          isListErrored={false}
          isEmptyList={!scanData?.data?.length}
          isListFetching={false}
          dataTestId={PR_SECRET_SCAN_DETAILS_TABLE}
          list={tableData}
          tableColumns={PRSecretScanDetailsTableStructure}
          extraCellsRenderObj={extraCellsRenderObj}
          notPaginated
        />
      </AccordionDetails>
    </Accordion>
  );
};
