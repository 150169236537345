import { ReactComponent as CriticalSeverity } from 'assets/icons/severity-critical.svg';
import { ReactComponent as HighSeverity } from 'assets/icons/severity-high.svg';
import { ReactComponent as MediumSeverity } from 'assets/icons/severity-medium.svg';
import { ReactComponent as LowSeverity } from 'assets/icons/severity-low.svg';
import { ReactComponent as InfoSeverity } from 'assets/icons/severity-info.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/empty.svg';

export const SeverityIcon = ({
  severityScore,
  iconSize,
}: {
  severityScore: number;
  iconSize: string;
}) => {
  switch (severityScore) {
    case 5:
      return (
        <CriticalSeverity
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
    case 4:
      return (
        <HighSeverity
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
    case 3:
      return (
        <MediumSeverity
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
    case 2:
      return (
        <LowSeverity
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
    case 1:
      return (
        <InfoSeverity
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
    default:
      return (
        <EmptyIcon
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      );
  }
};
