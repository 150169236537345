import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from '../../../utils/sort-order/sort';

interface alertsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
}

const initialState: alertsState = {
  activeSortedField: 'timestamp',
  activeSortedOrder: SortOrder.Desc,
};
const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: alertsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField = action.payload;
    },
    setActiveSortedOrder: (
      state: alertsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
  },
});

export const { setActiveSortedField, setActiveSortedOrder } =
  alertsSlice.actions;

export const alertsReducer = alertsSlice.reducer;
