import { FilterItem } from 'components/multi-filter/multi-filter';

export const BranchesFilterList: FilterItem[] = [
  {
    title: 'Default',
    filterId: 'only_default',
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Protection',
    filterId: 'only_protected',
    props: [
      {
        label: 'On',
        value: true,
      },
      {
        label: 'Off',
        value: false,
      },
    ],
  },
  {
    title: 'Environment Defined',
    filterId: 'only_environment',
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Triggers workflow',
    filterId: 'only_workflow',
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Important Branch',
    filterId: 'only_important',
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
];
