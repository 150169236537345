import { Box, FormControl, styled, Typography } from '@mui/material';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as ThreeLines } from 'assets/icons/three-lines.svg';
import { FontSizes } from 'constants/font-sizes';
import { SelectChangeEvent } from '@mui/material/Select';
import { Colors } from 'constants/colors';
import { SeverityIcon } from 'pages/dashboard/components/severity-icon/severity-icon';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

interface Props {
  value: string;
  showInformational?: boolean;
  onChange: (e: SelectChangeEvent) => void;
  informationalText?: string;
  dataTestId: string;
  useMedium?: boolean;
}
export const SeverityFilter: React.FC<Props> = ({
  value,
  onChange,
  showInformational,
  informationalText,
  dataTestId,
  useMedium,
}) => {
  const severities = [
    'Critical',
    'High',
    useMedium ? 'Medium' : 'Moderate',
    'Low',
    informationalText || 'Informational',
  ];
  if (!showInformational) severities.pop();
  const renderSeverity = (severity: number) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <SeverityIcon severityScore={severity} iconSize="16px" />
        <Typography
          sx={{ fontSize: FontSizes.fontSize14, color: Colors.darkGray }}
        >
          {severities[5 - severity]}
        </Typography>
      </Box>
    );
  };
  return (
    <StyledFormControl>
      <StyledSelect
        variant="outlined"
        color="info"
        size="medium"
        value={value}
        IconComponent={() => null}
        displayEmpty
        renderValue={(selected) => {
          if (selected === 'all') {
            return (
              <Typography
                lineHeight={'14px'}
                display={'flex'}
                alignContent={'center'}
              >
                <CustomSvgIcon
                  sx={{
                    fontSize: FontSizes.fontSize20,
                    width: '14px',
                    height: '14px',
                    marginRight: '6px',
                  }}
                >
                  <FilterIcon color={Colors.darkGray} />
                </CustomSvgIcon>
                Filter By Severity
              </Typography>
            );
          }
          if (typeof selected === 'string') {
            const severity = parseInt(selected);
            return renderSeverity(severity);
          }
        }}
        //@ts-ignore
        onChange={onChange}
        sx={{
          minWidth: '140px',
          width: 'auto',
          padding: '10px',
          fontSize: FontSizes.fontSize14,
          '& .MuiSelect-select': {
            paddingX: '0px !important',
          },
          '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
              textFillColor: `${Colors.blackPearl}!important`,
            },
          },
        }}
        data-testid={dataTestId}
        height="36px"
      >
        <StyledOptionItem value={'all'}>
          <CustomSvgIcon
            sx={{
              fontSize: FontSizes.fontSize14,
              width: '10px',
              height: '10px',
              marginRight: '6px',
            }}
          >
            <ThreeLines color={Colors.blackPearl} />
          </CustomSvgIcon>
          All
        </StyledOptionItem>
        {severities.map((severity, index) => (
          <StyledOptionItem value={(5 - index).toString()}>
            {renderSeverity(5 - index)}
          </StyledOptionItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  gap: 10,
  alignItems: 'center',
});
