import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const activityTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    withSort: true,
    position: 'left',
  },
  {
    id: 'created',
    title: 'Date',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'number',
    title: '#build',
    withSort: true,
    position: 'left',
  },
  {
    id: 'status',
    title: 'Status',
    position: 'left',
  },
  {
    id: 'graph',
    title: 'Sha',
    position: 'left',
  },
];

export const pipelineActivityFilterList: FilterItem[] = [
  {
    title: 'Status',
    filterId: 'status',
    filterColumns: ['status'],
    props: [
      {
        label: 'Success',
        value: 'success',
      },
      {
        label: 'Canceled',
        value: 'canceled',
      },
      {
        label: 'Skipped',
        value: 'skipped',
      },
      {
        label: 'Failed',
        value: 'failed',
      },
    ],
  },
];
