import { toast, ToastContainer } from 'react-toastify';

export const Toast = () => {
  return (
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      hideProgressBar
      autoClose={3000}
      style={{
        padding: 0,
        width: '405px',
      }}
      toastStyle={{
        padding: 0,
        background: 'transparent',
        boxShadow: 'none',
      }}
      closeButton={() => null}
    />
  );
};
