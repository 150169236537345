import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'login',
    title: 'Name',
    position: 'left',
  },
  {
    id: 'role',
    title: 'Title',
    position: 'left',
  },
  {
    id: 'num_contributions',
    title: 'No. of Contributions',
    position: 'left',
  },
  {
    id: 'transparency_score',
    title: 'Profile Reputation',
    position: 'left',
  },
  {
    id: 'age_score',
    title: 'Profile Age',
    position: 'left',
  },
  {
    id: 'activity_score',
    title: 'Experience',
    position: 'left',
  },
  {
    id: 'vulnerability_index',
    title: 'Security Record',
    position: 'left',
  },
];

export const contributorInfoStructure: InfoDetailsProps[] = [
  {
    id: 'login',
    title: 'Contributor',
  },
  {
    id: 'created_at',
    title: 'Joined On',
    isDate: true,
  },
  {
    id: 'num_followers',
    title: 'Followers',
  },
  {
    id: 'unique_package_count',
    title: 'Total Packages Contributed To',
  },
];
