import { useAppSelector } from '../../hooks';
import {
  IdentitiesAccountStatus,
  IdentitiesAccountType,
  IdentitiesUserItemRes,
} from '../../apis/types';
import { SortOrder } from 'utils/sort-order/sort';

export const useAccountTypeSelector = (): IdentitiesAccountType | undefined =>
  useAppSelector(({ identities }) => identities.accountType);

export const useAccountStatusSelector = ():
  | IdentitiesAccountStatus
  | undefined => useAppSelector(({ identities }) => identities.accountStatus);

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ identities }) => identities.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ identities }) => identities.activeSortedOrder);

export const useIdentitiesUserGroupsTeamsSelector = ():
  | Record<string, string>[]
  | undefined => useAppSelector(({ identities }) => identities.userGroupsTeam);

export const useActivityStartDateSelector = (): string | undefined =>
  useAppSelector(({ identities }) => identities.activityStartDate);

export const useActivityEndDateSelector = (): string | undefined =>
  useAppSelector(({ identities }) => identities.activityEndDate);

export const useSearchQuerySelector = (): string =>
  useAppSelector(({ identities }) => identities.searchQuery);

export const useCalendarTabSelector = (): number =>
  useAppSelector(({ identities }) => identities.calendarTab);

export const useActivityPeriodSelector = (): string =>
  useAppSelector(({ identities }) => identities.activityPeriod);

export const useRepoTableSearchQuerySelector = (): string =>
  useAppSelector(({ identities }) => identities.repoTableSearchQuery);

export const useMemberTableSearchQuerySelector = (): string =>
  useAppSelector(({ identities }) => identities.memberTableSearchQuery);

export const useEditRowsSelector = (): IdentitiesUserItemRes[] =>
  useAppSelector(({ identities }) => identities.editRows);

export const useHoveredRowSelector = (): any =>
  useAppSelector(({ identities }) => identities.hoveredRow);

export const useIdentityDetailsTabSelector = (): number =>
  useAppSelector(({ identities }) => identities.identitiesDetailsTab);
