import { InfoComponent } from 'components/info-component/info-component';
import { HeaderBackButton } from 'components/page-components/page-header/header-back-button';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trivyScanInfoStructure } from '../constants';
import { TRIVY_SCAN_INFO_COMPONENT } from 'constants/test-ids';
import { Box, FormControl, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { TrivyScanRow } from '../components/trivy-scan-row';

export const TrivyScanDetails = () => {
  const apiContext = useContext(ApiContext);
  const { org, repo, pr_number, check_run_id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [groupBy, setGroupBy] = useState('severity');

  const { data, isLoading, isError } =
    apiContext.baseApi.useGetTrivyScanDetailsQuery({
      org: org!,
      repo: repo!,
      pr_number: parseInt(pr_number!),
      check_run_id: parseInt(check_run_id!),
    });

  const groupByFiles = () => {
    const files = Array.from(
      new Set(data?.data?.map((item: any) => item.file)),
    );
    let scanData: any = [];
    files.forEach((file) => {
      const filteredData = data.data.filter((item: any) => item.file === file);
      let fileData: any = {};
      fileData['file'] = [file];
      const severities = filteredData.map((item: any) => item.severity);

      const severityCounts = severities.reduce(
        (counts: any, severity: string) => {
          counts[severity] = (counts[severity] || 0) + 1;
          return counts;
        },
        {},
      );
      fileData['severity'] = severityCounts;
      fileData['data'] = filteredData;
      scanData.push(fileData);
    });
    setTableData(scanData);
  };

  const groupBySeverity = () => {
    const severities = Array.from(
      new Set(data?.data?.map((item: any) => item.severity)),
    ) as string[];
    const order = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO'];

    severities.sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
    let scanData: any = [];
    severities.forEach((severity) => {
      const filteredData = data.data.filter(
        (item: any) => item.severity === severity,
      );
      let severityData: any = {};
      severityData['severity'] = severity;
      const files = Array.from(
        new Set(filteredData.map((item: any) => item.file)),
      );
      severityData['file'] = files;
      severityData['data'] = filteredData;
      scanData.push(severityData);
    });
    setTableData(scanData);
  };

  useEffect(() => {
    if (data?.data) {
      if (groupBy === 'file') groupByFiles();
      else if (groupBy === 'severity') groupBySeverity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groupBy]);

  useEffect(() => {
    if (data?.data) {
      let info: any = { org, repo, pr_number, check_run_id };
      const source = data?.data?.map((item: any) => item?.source);
      if (source) info['source'] = source[0];
      const type = data?.data?.map((item: any) => item?.type);
      if (type) info['type'] = type[0];
      setInfoData(info);
    }
  }, [data, org, repo, pr_number, check_run_id]);

  const GroupBy = () => {
    return (
      <FormControl
        sx={{
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h3"
          sx={{ margin: '0', color: Colors.whiteTransparent05 }}
        >
          Group By
        </Typography>
        <StyledSelect
          variant="outlined"
          color="info"
          size="medium"
          value={groupBy}
          //@ts-ignore
          onChange={(e) => setGroupBy(e.target.value)}
          sx={{ minWidth: '120px', width: 'auto' }}
        >
          <StyledOptionItem value={'severity'}>Severity</StyledOptionItem>
          <StyledOptionItem value={'file'}>Files</StyledOptionItem>
        </StyledSelect>
      </FormControl>
    );
  };

  return (
    <>
      <HeaderBackButton />
      <InfoComponent
        data={infoData}
        isLoading={isLoading}
        isError={isError}
        infoStructure={trivyScanInfoStructure}
        dataTestId={TRIVY_SCAN_INFO_COMPONENT}
      />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <GroupBy />
      </Box>
      <ContainerWithLoader isLoading={isLoading} isError={isError}>
        <Box sx={{ width: '100%', overflowY: 'auto', marginTop: '20px' }}>
          {!!tableData.length &&
            tableData.map((scan: any, index) => (
              <TrivyScanRow scanData={scan} index={index} />
            ))}
        </Box>
      </ContainerWithLoader>
    </>
  );
};
