import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  InputsSchemas,
  IntegrationItem,
  TabsTypes,
} from 'pages/connectors/types';

export interface ConnectorsState {
  activeIntegration: IntegrationItem;
  integrationsListState: TabsTypes;
}

export const IntegrationItemEmptyState = {
  connector_id: '' as InputsSchemas,
  name: '',
  image_c: '',
  ab_spec: {
    $schema: '',
  },
  active: false,
  inputs_schema: {
    $schema: '',
  },
};

const initialState: ConnectorsState = {
  activeIntegration: IntegrationItemEmptyState,
  integrationsListState: 0,
};

export const connectorsSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    setActiveIntegration: (
      state: ConnectorsState,
      action: PayloadAction<IntegrationItem>,
    ) => {
      state.activeIntegration = action.payload;
    },
    setIntegrationsListState: (
      state: ConnectorsState,
      action: PayloadAction<TabsTypes>,
    ) => {
      state.integrationsListState = action.payload;
    },
  },
});

export const { setActiveIntegration, setIntegrationsListState } =
  connectorsSlice.actions;

export const connectorsReducer = connectorsSlice.reducer;
