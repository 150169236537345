import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'timestamp',
    title: 'Time',
    position: 'left',
    isDate: true,
  },
  {
    id: 'username',
    title: 'User',
    position: 'left',
  },
  {
    id: 'description',
    title: 'Action',
    position: 'left',
  },
  {
    id: 'payload',
    title: 'View Details',
    position: 'left',
  },
];
