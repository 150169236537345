/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import {
  IDENTITIES_TABLE_NAME_LINK,
  POLICY_LIBRARY_SEVERITY_FILTER,
  SAST_MAIN_TABLE,
  SAST_PAGE_HEADER,
  SAST_SCAN_CSV_DOWNLOAD,
} from '../../constants/test-ids';
import { PageHeader } from '../../components/page-components/page-header/page-header';
import he from 'he';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  ExtraCellsObject,
  TableComponent,
} from '../../components/table/table-component/table-component';
import { useGetResponseDataWithPagination } from '../../hooks/use-get-response-data-with-pagination';
import { ApiContext } from '../../components/providers/api-provider/api-provider';
import { tableStructure } from './constants';
import { Colors } from '../../constants/colors';
import { FontSizes } from '../../constants/font-sizes';
import dayjs from 'dayjs';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import {
  RightTableFiltersContainer,
  TableFiltersContainer,
} from 'components/table/styles';
import { SeverityFilter } from 'pages/alerts/components/severity-filter/severity-filter';
import { useDispatch } from 'react-redux';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';
import { RenderSourceCell } from 'components/info-component/info-component';
import { FilterChips } from 'components/table/table-filters-components/filter-chips/chips-list';
import {
  setApplicableFilters,
  setColumnCoordinates,
  setSelectedColumn,
  setSelectedTable,
} from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { CsvDownload } from 'components/table/table-filters-components/csv-download/csv-download';
import { StyledButton } from 'components/button/button';
import { StyledTooltip } from 'components/tooltip/tooltip';

const mapSeverityToText: any = {
  '2': 'low',
  '3': 'medium',
  '4': 'high',
  '5': 'critical',
};

export const DastScan = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableId = FiltersTableName.DAST_FINDINGS_TABLE;
  const {
    applicableFilters: tableFilters,
    activeSortedField,
    activeSortedOrder,
  } = useSelectedColumnSelector(tableId);

  const [selectedSeverity, setSelectedSeverity] = React.useState(
    tableFilters?.severity?.toString() || 'all',
  );
  const handleSeverityDropdownChange = (event: SelectChangeEvent) => {
    const severity = event.target.value;
    setSelectedSeverity(severity);
    dispatch(
      setApplicableFilters({
        table: tableId,
        applicableFilters: severity !== 'all' ? { severity: severity } : {},
      }),
    );
  };
  useEffect(() => {
    dispatch(setSelectedTable({ table: tableId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useLazyGetDastScanDataQuery =
    apiContext.baseApi?.useLazyGetDastScanDataQuery;

  const [open, setOpen] = React.useState(false);
  const [stateData, setStateData] = React.useState<any>(null);

  const handleOpen = (item: any) => {
    setStateData(item);
    setOpen(true);
  };

  const handleClose = () => {
    setStateData(null);
    setOpen(false);
  };

  const {
    dataList: sastScanList,
    isLoading: isSastDataLoading,
    isError: isSastDataErrored,
    isFetching: isSastDataFetching,
    isNoFindings: isEmptySastData,
    total,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetDastScanDataQuery,
    itemsPerPage: 10,
    tableFilters,
    severity:
      selectedSeverity === 'all'
        ? undefined
        : mapSeverityToText[selectedSeverity],
    tableId,
  });

  const dialogContent = () => {
    let remedyString = '';
    let vulnString = '';
    if (stateData?.remedy) remedyString = he.decode(stateData.remedy);
    if (stateData?.vulnerability_detail)
      vulnString = he.decode(stateData.vulnerability_detail);
    return (
      <>
        {
          <>
            {stateData?.type && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  Type:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                >
                  {stateData.type}
                </Typography>
              </Box>
            )}
            {stateData?.url && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  URL:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                >
                  {stateData.url}
                </Typography>
              </Box>
            )}
            {stateData?.is_addressed != null && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  Addessed:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                >
                  {stateData.is_addressed ? 'Yes' : 'No'}
                </Typography>
              </Box>
            )}
            {stateData?.is_present != null && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  Present:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                >
                  {stateData.is_present ? 'Yes' : 'No'}
                </Typography>
              </Box>
            )}
            {stateData?.latest_vulnerability_is_confirmed != null && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ maxWidth: '150px' }}
                >
                  Latest Vulnerability Confirmed:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                >
                  {stateData.latest_vulnerability_is_confirmed ? 'Yes' : 'No'}
                </Typography>
              </Box>
            )}
            {stateData?.remedy && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  Remedy:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  sx={{
                    fontWeight: '600 !important',
                    '& *': {
                      fontWeight: '600 !important',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: remedyString }}
                />
              </Box>
            )}
            {stateData?.vulnerability_detail && (
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  color={Colors.darkGray}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={500}
                  sx={{ minWidth: '150px' }}
                >
                  Vulnerability Details:{' '}
                </Typography>
                <Typography
                  color={Colors.blackPearl}
                  fontSize={FontSizes.fontSize16}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{ __html: vulnString }}
                />
              </Box>
            )}
          </>
        }
      </>
    );
  };

  const RenderStateCell = (item: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{ color: Colors.binge, cursor: 'pointer' }}
          onClick={() => handleOpen(item)}
        >
          View Data
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          hideBackdrop
          sx={{
            '& .MuiDialog-paper': {
              background: Colors.white,
            },
          }}
        >
          <DialogContent>
            <Box sx={{ p: 2 }}>{dialogContent()}</Box>
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={handleClose}
              color="secondary"
              variant="outlined"
              size="large"
            >
              Close
            </StyledButton>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const RenderCreatedAt = (item: any) => {
    return dayjs(item.created_at).format('L LT');
  };

  const RenderUpdatedAt = (item: any) => {
    return dayjs(item.updated_at).format('L LT');
  };

  const RenderAuthorLoginCell = (item: any) => {
    return (
      <>
        {item?.author_email ? (
          <TableCellWithLink
            handleClick={() => {
              handleClose();
              navigate(
                `${paths.identityDetails}?user=${item?.author_email}${
                  item.source ? '&source=' + item.source : ''
                }`,
              );
            }}
            isActive={true}
            itemTitle={item.author_email}
            align="left"
            dataTestId={IDENTITIES_TABLE_NAME_LINK}
          />
        ) : (
          '-'
        )}
      </>
    );
  };

  const renderUrlCell = (item: any) => {
    return (
      <StyledTooltip title={item.url}>
        <Typography>{`${item.url.substring(0, 15)}${
          item.url.length > 15 ? '...' : ''
        }`}</Typography>
      </StyledTooltip>
    );
  };

  const renderSourceCell = (item: any) => {
    return item?.source && <RenderSourceCell source={item?.source} />;
  };

  const SastExtraCell: ExtraCellsObject = {
    causeMetadata: RenderStateCell,
    url: renderUrlCell,
    author_email: RenderAuthorLoginCell,
    created_at: RenderCreatedAt,
    updated_at: RenderUpdatedAt,
    source: renderSourceCell,
  };

  return (
    <Box>
      <PageHeader title="DAST Scan" dataTestId={SAST_PAGE_HEADER} />
      <TableFiltersContainer>
        {' '}
        <RightTableFiltersContainer>
          <FilterChips tableId={tableId} />
          <CsvDownload
            total={total}
            activeSortedField={activeSortedField}
            activeSortedOrder={activeSortedOrder}
            apiUrl="findings/sast/detections"
            fileName="Sast Scan Finding"
            tableFilters={tableFilters}
            dataTestId={SAST_SCAN_CSV_DOWNLOAD}
          />
        </RightTableFiltersContainer>
      </TableFiltersContainer>
      <TableComponent
        tableId={tableId}
        isListLoading={isSastDataLoading}
        isListErrored={isSastDataErrored}
        isEmptyList={isEmptySastData}
        isListFetching={isSastDataFetching}
        dataTestId={SAST_MAIN_TABLE}
        list={sastScanList}
        tableColumns={tableStructure}
        extraCellsRenderObj={SastExtraCell}
        tableMinHeight="60vh"
        columnFilterClickHandler={(column: any, coordinates?: any) => {
          dispatch(setSelectedColumn({ table: tableId, column: column?.id }));
          dispatch(setColumnCoordinates({ coordinates, table: tableId }));
        }}
      />
    </Box>
  );
};
