import { useState, FC } from 'react';
import { Button, Menu, MenuList, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { StyledOptionItem } from 'components/inputs/select/select';

interface Props {
  index: string;
  disabled: boolean;
  menuItems: {
    id: string;
    title: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
  sbom?: boolean;
  noBorder?: boolean;
  buttonTitle?: string;
  dataTestId: string;
}

export const PopoverSelect: FC<Props> = ({
  index,
  menuItems,
  sbom,
  noBorder,
  disabled,
  buttonTitle,
  dataTestId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleGearIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`basic-button-${index}`}
        disabled={disabled}
        aria-controls={open ? `basic-menu-${index}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        data-testid={dataTestId}
        onClick={handleGearIconClick}
        sx={{
          border: noBorder ? 'none' : '1px solid',
          borderRadius: '5px',
          padding: '5px 10px',
          height: '36px',
          width: '200px',
          borderColor: Colors.darkGray,
          textTransform: 'capitalize',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize14,
            color: Colors.darkGray,
            lineHeight: '22px',
          }}
        >
          {buttonTitle || 'Action'}
        </Typography>
        {!open && (
          <KeyboardArrowDown
            sx={{
              color: Colors.darkGray,
              width: '16px',
              height: '16px',
            }}
          />
        )}
        {open && (
          <KeyboardArrowUp
            sx={{
              display: 'inline',
              color: Colors.darkGray,
              width: '16px',
              height: '16px',
            }}
          />
        )}
        <Menu
          id={`basic-menu-${index}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': `basic-button-${index}`,
          }}
          sx={{
            '.MuiPaper-root': {
              color: Colors.blackPearl,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <MenuList
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingX: '10px',
              gap: '5px',
              width: '200px',
              borderRadius: '10px',
              backgroundColor: Colors.white,
            }}
          >
            {menuItems.map((item) => (
              <StyledOptionItem
                sx={{
                  padding: '0px',
                }}
                key={item.id}
                data-testid={item.id}
                fontSize={FontSizes.fontSize14}
                onClick={(e) => {
                  item.onClick();
                  handleClose(e);
                }}
                disabled={item.disabled}
              >
                {item.title}
              </StyledOptionItem>
            ))}
          </MenuList>
        </Menu>
      </Button>
    </>
  );
};
