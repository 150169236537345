import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, InputAdornment, Typography } from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { NewUserFormInputs } from './types';
import { UserFields } from './constants';
import { emailPattern } from 'utils/general/reg-exp-patterns';
import { ToastMessage } from 'components/toast/toast-message';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { StyledFormControl } from 'pages/connectors/components/connector-details/components/inputs-schemas/styles';
import { FontSizes } from 'constants/font-sizes';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import {
  StyledSelect,
  StyledOptionItem,
} from 'components/inputs/select/select';
import { Colors } from 'constants/colors';
import { ReactComponent as UserIcon } from 'assets/icons/user-v2.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as RoleIcon } from 'assets/icons/role-select.svg';
import { StyledTextField } from 'components/range-input/range-input';

export const InviteUserModal = () => {
  const dispatch = useDispatch();
  const isInviteUserOpenModal = useIsPlainModalOpenSelector(
    PlainModals.INVITE_USER_MODAL,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<NewUserFormInputs>({
    defaultValues: {
      [UserFields.name]: '',
      [UserFields.email]: '',
      [UserFields.role]: '',
    },
  });

  const apiContext = useContext(ApiContext);
  const [inviteUser] = apiContext.baseApi.useInviteUserMutation();

  const handleCloseModal = () =>
    dispatch(closeModalByNameAction(PlainModals.INVITE_USER_MODAL));

  const handleConfirm = () => {
    handleSubmit(async (data) => {
      try {
        const { name, email, role } = data;
        await inviteUser({
          name,
          email,
          role,
        });
        handleCloseModal();
        toast(
          <ToastMessage
            type="success"
            title="Your request has been successfully processed"
          />,
        );
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    })();
  };

  const onRoleSelect = (id: string) => {
    setValue('role', id, { shouldValidate: true });
  };

  const [userRole] = watch([UserFields.role]);
  const name = watch(UserFields.name);
  const email = watch(UserFields.email);
  const isNameValid = name.length > 0 && name.length <= 30;
  const isEmailValid = emailPattern.test(email);

  return (
    <ModalContainer
      handleCloseModal={handleCloseModal}
      handleConfirm={handleConfirm}
      isOpenModal={isInviteUserOpenModal}
      title="Invite a User"
      closeModalTitle="Cancel"
      confirmModalTitle="Send Invitation"
      modalHeight="auto"
      modalWidth="500px"
      left="32.5%"
      top="23%"
      alignItems="start"
      disableConfirm={!isNameValid || !isEmailValid || !userRole}
    >
      <Typography
        color={Colors.darkGray}
        fontSize={FontSizes.fontSize12}
        paddingTop={'20px'}
        borderTop={`1px solid ${Colors.lightSilver}`}
        width={'100%'}
      >
        Email address and role selection are required.
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingTop: '10px',
          rowGap: '15px',
        }}
      >
        <StyledFormControl
          sx={{
            width: '100%',
          }}
        >
          <StyledTextField
            fullWidth
            label="Username"
            {...register(UserFields.name, {
              minLength: 1,
              maxLength: 30,
              required: true,
            })}
            placeholder="Username"
            sx={{
              '& svg': {
                color: Colors.darkGray,
              },
              '& .Mui-focused svg': {
                color: Colors.black,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <UserIcon />
                </InputAdornment>
              ),
            }}
            margin="dense"
            error={errors.hasOwnProperty(UserFields.name)}
          />
        </StyledFormControl>
        <StyledFormControl
          sx={{
            width: '100%',
          }}
        >
          <StyledTextField
            fullWidth
            label="Email"
            {...register(UserFields.email, {
              required: true,
              pattern: emailPattern,
            })}
            placeholder="Email"
            error={errors.hasOwnProperty(UserFields.email)}
            sx={{
              '& svg': {
                color: Colors.darkGray,
              },
              '& .Mui-focused svg': {
                color: Colors.black,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            margin="dense"
          />
        </StyledFormControl>
        <StyledFormControl sx={{ width: '100%' }}>
          <StyledSelect
            variant="outlined"
            color="info"
            size="medium"
            height="36px"
            onChange={(event) => onRoleSelect(event.target.value as string)}
            displayEmpty
            renderValue={(selected) => {
              return (
                <Box
                  display={'flex'}
                  gap={'7px'}
                  alignItems={'center'}
                  paddingLeft={'9px'}
                >
                  <RoleIcon
                    color={!selected ? Colors.darkGray : Colors.blackPearl}
                  />
                  {!selected ? (
                    <Typography>Select</Typography>
                  ) : typeof selected === 'string' ? (
                    <Typography
                      textTransform={'capitalize'}
                      color={Colors.blackPearl}
                      sx={{ WebkitTextFillColor: Colors.blackPearl }}
                    >
                      {selected}
                    </Typography>
                  ) : null}
                </Box>
              );
            }}
            value={userRole}
            error={errors.hasOwnProperty(UserFields.role)}
          >
            <StyledOptionItem value="user">User</StyledOptionItem>
            <StyledOptionItem value="admin">Administrator</StyledOptionItem>
          </StyledSelect>
        </StyledFormControl>
      </Box>
    </ModalContainer>
  );
};
