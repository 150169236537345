import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface AssetsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  activePipelineActivityWorkflowId: number | null;
  activeDependencyPackage?: string;
  activeTab: number;
  activeFindingsTab: number;
  customTab: number;
  mergedPullRequests: boolean;
  ossLanguages: string[];
}

const initialState: AssetsState = {
  activeSortedField: 'last_activity',
  activeSortedOrder: SortOrder.Desc,
  activePipelineActivityWorkflowId: null,
  activeTab: 0,
  activeFindingsTab: 0,
  customTab: 0,
  mergedPullRequests: false,
  ossLanguages: [],
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: AssetsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: AssetsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setActivePipelineActivityWorkflowId: (
      state: AssetsState,
      action: PayloadAction<number | null>,
    ) => {
      state.activePipelineActivityWorkflowId = action.payload;
    },
    setActiveDependencyPackage: (
      state: AssetsState,
      action: PayloadAction<string>,
    ) => {
      state.activeDependencyPackage = action.payload;
    },
    setActiveTab: (state: AssetsState, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setActiveFindingsTab: (
      state: AssetsState,
      action: PayloadAction<number>,
    ) => {
      state.activeFindingsTab = action.payload;
    },
    setCustomTab: (state: AssetsState, action: PayloadAction<number>) => {
      state.customTab = action.payload;
    },
    setMergedPullReqs: (state: AssetsState, action: PayloadAction<boolean>) => {
      state.mergedPullRequests = action.payload;
    },
    setOssLanguages: (state: AssetsState, action: PayloadAction<string[]>) => {
      state.ossLanguages = action.payload;
    },
    resetCustomTab: (state: AssetsState) => {
      state.mergedPullRequests = false;
      state.customTab = 0;
    },
  },
});

export const {
  setActiveSortedField,
  setActiveSortedOrder,
  setActiveDependencyPackage,
  setActivePipelineActivityWorkflowId,
  setActiveTab,
  setActiveFindingsTab,
  setCustomTab,
  setMergedPullReqs,
  setOssLanguages,
  resetCustomTab,
} = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
