import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';
import { Colors } from 'constants/colors';

export const alertsTableStructure: TableColumnProps[] = [
  {
    id: 'detection',
    title: 'Detection',
    withSort: true,
    position: 'left',
  },
  {
    id: 'time',
    title: 'Time',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'policy',
    title: 'Policy',
    withSort: true,
    position: 'left',
  },
];

export const alertsFilterList: FilterItem[] = [
  {
    title: 'Severity',
    filterId: 'severity_filter',
    filterColumns: ['detection'],
    props: [
      {
        label: 'Critical',
        value: '5',
      },
      {
        label: 'High',
        value: '4',
      },
      {
        label: 'Medium',
        value: '3',
      },
      {
        label: 'Low',
        value: '2',
      },
      {
        label: 'Informational',
        value: '1',
      },
    ],
  },
  {
    title: 'Status',
    filterId: 'status_filter',
    filterColumns: ['detection'],
    props: [
      {
        label: 'Sent',
        value: 'sent',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Open',
        value: 'open',
      },
    ],
  },
];

export const mapColorsToSeverity = {
  1: Colors.whiteTransparent07,
  2: Colors.yellow,
  3: Colors.orange,
  4: Colors.lightRed,
  5: Colors.red,
};
