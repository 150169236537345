import { TableColumnProps } from 'components/table/table-component/table-component';

export const multiToolTableStructure: TableColumnProps[] = [
  {
    id: 'source',
    title: 'Tool',
    position: 'left',
  },
  {
    id: 'login',
    title: 'Account Name',
    position: 'left',
  },
  {
    id: 'email',
    title: 'Email(s)',
    position: 'left',
    customComponent: (data: any) =>
      data.email && data.email.length ? data.email.join(', ') : '-',
  },
  {
    id: 'status',
    title: 'Status',
    position: 'left',
  },
  {
    id: 'last_activity_date',
    title: 'Last Activity',
    position: 'left',
    isDate: true,
  },
];
