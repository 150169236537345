import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as AccountBoxIcon } from 'assets/icons/user-v2.svg';
import { GlobalStyles } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useKeycloak } from '@react-keycloak/web';
import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out.svg';
import { ReactComponent as EntityGraphIcon } from 'assets/icons/graph.svg';
import { Navigation } from 'components/navigation/navigation';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { Colors } from 'constants/colors';
import { paths } from 'constants/routes';
import {
  ENTITY_GRAPH_BUTTON,
  GLOBAL_SEARCH_BUTTON,
  MAIN_WRAPPER,
  USER_PROFILE_BUTTON,
  USER_SIGNOUT_BUTTON,
} from 'constants/test-ids';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useGetRoles } from 'store/modules/auth/auth.selectors';
import './App.css';
import { CollapseIcon } from './components/collapse-icon/collapse-icon';
import { Modals } from './components/modals/modals';
import { Toast } from './components/toast/toast';

interface Props {
  renderMainContent: () => ReactNode;
}

export function MainContent({ renderMainContent }: Props) {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const roles = useGetRoles();
  const apiContext = useContext(ApiContext);
  const [userLoginInfo, setUserLoginInfo] = useState<Record<any, any>>({});
  const developerOnly = roles?.length === 1 && roles[0] === 'developer';
  console.log('ROLES', roles);
  const { data: userData, refetch } = apiContext.baseApi?.useGetUserInfoQuery();
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
  const [expandClick, setExpandClick] = useState(true);
  useEffect(() => {
    refetch();
  }, [refetch]);

  const { data: connectedIntegrations } =
    apiContext.baseApi?.useGetAllCurrentConnectionsQuery();

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setUserLoginInfo(userData);
    }
    if (developerOnly && userLoginInfo?.login) {
      navigate(`${paths.identityDetails}?user=self`);
    }
    if (
      connectedIntegrations !== undefined &&
      connectedIntegrations.length === 0 &&
      !window.location.pathname.includes('user-management')
    ) {
      navigate(paths.connectors);
    }

    if (window.location.pathname.includes('commit-details')) {
      setIsDrawerExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, developerOnly, userLoginInfo, userData, connectedIntegrations]);

  const showNavBar = () => {
    return connectedIntegrations?.length !== 0 && !developerOnly;
  };

  useEffect(() => {
    if (keycloak && initialized && keycloak.tokenParsed && userData) {
      const sessionMaxTime =
        ((keycloak.tokenParsed.iat || Date.now()) +
          userData.ssoSessionMaxLifespan) *
        1000;
      const currentTime = Date.now();
      const timeUntilExpiration = sessionMaxTime - currentTime;

      if (timeUntilExpiration > 0) {
        const timeoutId = setTimeout(() => {
          keycloak.logout();
        }, timeUntilExpiration);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [keycloak, initialized, keycloak.tokenParsed, userData]);

  // useEffect(() => {
  //   if (keycloak && initialized && userData) {
  //     let idleTimer: any;

  //     const resetTimer = () => {
  //       if (idleTimer) clearTimeout(idleTimer);
  //       idleTimer = setTimeout(() => {
  //         keycloak.logout();
  //       }, userData.ssoSessionIdleTimeout * 1000);
  //     };

  //     window.addEventListener('mousemove', resetTimer);
  //     window.addEventListener('keypress', resetTimer);

  //     resetTimer();

  //     return () => {
  //       if (idleTimer) clearTimeout(idleTimer);
  //       window.removeEventListener('mousemove', resetTimer);
  //       window.removeEventListener('keypress', resetTimer);
  //     };
  //   }
  // }, [keycloak, initialized, userData]);

  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: Colors.bodyBackground,
            color: Colors.blackPearl,
          },
        }}
      />
      {roles.length > 0 && (
        <Box sx={{ display: 'flex', maxHeight: '100vh' }}>
          {CustomGlobalStyles}
          {
            <>
              <Navigation
                isAdmin={roles.includes('admin')}
                connectorsExist={showNavBar()}
                isDrawerExpanded={isDrawerExpanded}
                setIsDrawerOpen={setIsDrawerExpanded}
                expandClick={expandClick}
                setExpandClick={setExpandClick}
              />
              {!(isDrawerExpanded && !expandClick) && (
                <CollapseIcon
                  isDrawerExpanded={isDrawerExpanded}
                  setIsDrawerExpanded={setIsDrawerExpanded}
                  setExpandClick={setExpandClick}
                  expandClick={expandClick}
                />
              )}
            </>
          }
          {/* {isDrawerExpanded && !expandClick ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: Colors.fadedBackground,
                position: 'fixed',
                flexGrow: 1,
                zIndex: 8,
              }}
            />
          ) : (
            <></>
          )} */}
          <Modals />
          <Toast />
          <Box width={'100%'}>
            <Box
              height="46px"
              display="flex"
              justifyContent="end"
              alignItems="center"
              gap={'15px'}
              sx={{
                width: '100%',
                padding: '15px 15px',
                background: Colors.menuGradient,
                position: 'fixed',
                top: '0px',
                zIndex: 999,
              }}
            >
              <Box
                onClick={() => navigate(paths.entityGraph)}
                data-testid={ENTITY_GRAPH_BUTTON}
                sx={{ cursor: 'pointer' }}
              >
                <EntityGraphIcon
                  style={{
                    color: Colors.white,
                    width: '16px',
                    height: '16px',
                  }}
                />
              </Box>
              <Box data-testid={GLOBAL_SEARCH_BUTTON}>
                <Search
                  style={{
                    height: '16px',
                    cursor: 'pointer',
                    color: Colors.white,
                  }}
                  onClick={() => navigate('/global-search')}
                />
              </Box>
              {userLoginInfo !== null &&
              userLoginInfo !== undefined &&
              Object.keys(userLoginInfo).length !== 0 ? (
                <Box
                  onClick={() => navigate(`${paths.identityDetails}?user=self`)}
                  data-testid={USER_PROFILE_BUTTON}
                  color={Colors.white}
                >
                  <AccountBoxIcon
                    height="16px"
                    style={{ cursor: 'pointer', color: Colors.white }}
                  />
                </Box>
              ) : (
                ''
              )}
              <Box
                onClick={() => keycloak.logout()}
                data-testid={USER_SIGNOUT_BUTTON}
                color={Colors.white}
              >
                <SignOutIcon height="16px" style={{ cursor: 'pointer' }} />
              </Box>
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: Colors.bodyBackground,
                padding: '30px 30px',
                pl: isDrawerExpanded && expandClick ? '315px' : '90px',
                marginTop: '46px',
                transition: 'all 0.3s ease',
              }}
              data-testid={MAIN_WRAPPER}
            >
              {renderMainContent()}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const CustomGlobalStyles = (
  <GlobalStyles
    styles={{
      '.hide-resize-observer': {
        display: 'none !important',
      },

      '.MuiPickersPopper-root': {
        '& .MuiPaper-root': {
          backgroundColor: Colors.white,
          color: Colors.blackPearl,
          '& .MuiMultiSectionDigitalClock-root': {
            borderLeft: 'none',
          },
        },
        '& .Mui-selected': {
          backgroundColor: `${Colors.binge} !important`,
          color: `${Colors.white} !important`,
        },
        '& .MuiDialogActions-root .MuiButtonBase-root': {
          color: `${Colors.binge} !important`,
        },
      },
      '#dependencyTreeContainer': {
        path: {
          fill: 'none',
          stroke: Colors.darkGray,
        },

        text: {
          fontSize: '10px',
          fill: Colors.blackPearl,
        },
      },
      'input[data-autocompleted]': {
        'background-color': 'transparent !important;',
      },
      '.MuiCheckbox-root.Mui-checked': {
        color: Colors.binge,
      },
    }}
  />
);
